import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { TestTube } from 'lucide-react';

export default function TestModeDialog({
  testModeNewProjectId,
  setOpen,
  open,
  onClick,
}: {
  testModeNewProjectId: number;
  setOpen: any;
  open: boolean;
onClick?: any;
}) {
  const [projName, setProjName] = React.useState('');

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          {/* <DialogTitle>Introducing Test Mode</DialogTitle> */}
          <DialogDescription className="flex flex-col justify-center items-center w-full">
            <div className="w-full mt-4 mx-auto transition-all">
              <div className="mx-auto flex items-center justify-center w-10 h-10 rounded-lg border border-orange-500 bg-orange-500/25  gap-4">
                <TestTube className="w-6 h-6 text-orange-500" />
              </div>
                <h2 className="mx-auto self-center mt-4 flex items-center justify-center text-2xl font-bold text-gray-100">
                    Introducing Test Mode
                </h2>
              <p className="mt-6 text-base font-medium text-gray-300">
                Test Mode allows you to work with a copy of your project without
                affecting the original. This is useful for testing new features
                without affecting your production data. 
              </p>
              <p className="mt-6 text-base font-medium text-gray-300">
              Once you toggle Test Mode, a new project will be created with a copy of your data.
                You can then make changes to this new project without affecting
                the original and vice versa. 
              </p>
              <p className="mt-6 text-base font-medium text-gray-300">
              Toggle between the original and test project at anytime.
              </p>

              <div className="mt-7">
                <Button
                  className="bg-orange-500 w-full hover:bg-opacity-50"
                  onClick={() => {
                    onClick(testModeNewProjectId);
                    setOpen(false);
                  }}
                >
                  Got it!
                </Button>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
