import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { HomeIcon, SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { TiFlowMerge } from 'react-icons/ti';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Logo from '@/components/icons/Logo';

import { FiDatabase, FiTool, FiRefreshCw, FiFolder } from 'react-icons/fi';

import { posthog } from 'posthog-js';
import { toast as sonnerToast } from 'sonner';

import {
  Bars3BottomLeftIcon,
  ChartBarIcon,
  KeyIcon,
  ChatBubbleLeftRightIcon
} from '@heroicons/react/24/outline';
import {
  MdTimeline,
  MdArrowBack,
  MdInsights,
  MdLock,
  MdSettings,
  MdOutlineBubbleChart
} from 'react-icons/md';
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { MendableProject } from 'types';
import { HeroButton } from '../ui/Button/HeroButton';
import { Button } from './ui/button';
import { feedbackState } from '../atoms/feedback';
// import { useRecoilState } from 'recoil';
import { AiOutlineLink } from 'react-icons/ai';
import { CgArrowUpR, CgFileDocument, CgSpinner } from 'react-icons/cg';
import { postData } from '@/utils/helpers';
import HelpPopover from './HelpPopover';
import UpgradeDialog from './UpgradeDialog';
import UpgradeTrialDialog from './UpgradeTrialDialog';
import EmbedPopover from './EmbedPopover';
import { isFreePlan } from '@/utils/pricing';
import { MendableFloatingButton } from '@mendable/search';
import {
  companies_with_router,
  companies_with_tools
} from '@/lib/router_access';
import { useBearStore } from '@/lib/zustand/test';
import NotificationsPopover from './NotificationsPopover';
import { Switch, TestModeSwitch } from '../ui/switch';
import TestModeDialog from './dashboard/TestModeDialog';
import Banner, { TestModeBanner } from '../Banner';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

function ToolIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5382_269)">
        <path
          d="M39.9657 34.3704C43.2386 34.3704 45.8916 31.7174 45.8916 28.4445C45.8916 25.1716 43.2386 22.5186 39.9657 22.5186C36.6928 22.5186 34.0398 25.1716 34.0398 28.4445C34.0398 31.7174 36.6928 34.3704 39.9657 34.3704ZM45.5645 39.9686C45.5645 43.2415 48.2175 45.8946 51.4904 45.8946C54.7627 45.8946 57.4164 43.2415 57.4164 39.9686C57.4164 36.6957 54.7627 34.0427 51.4904 34.0427C48.2175 34.0427 45.5645 36.6957 45.5645 39.9686ZM39.9657 45.5674C36.6928 45.5674 34.0398 48.2205 34.0398 51.4934C34.0398 54.7657 36.6928 57.4193 39.9657 57.4193C43.2386 57.4193 45.8916 54.7657 45.8916 51.4934C45.8916 48.2205 43.2386 45.5674 39.9657 45.5674ZM34.3675 39.9686C34.3675 36.6957 31.7144 34.0427 28.4416 34.0427C25.1687 34.0427 22.5156 36.6957 22.5156 39.9686C22.5156 43.2415 25.1687 45.8946 28.4416 45.8946C31.7144 45.8946 34.3675 43.2415 34.3675 39.9686Z"
          fill="currentColor"
        />
        <path
          d="M16.7053 23.8871C20.6324 23.8871 23.8164 20.7025 23.8164 16.776C23.8164 12.8483 20.6324 9.66484 16.7053 9.66484C12.7776 9.66484 9.59422 12.8483 9.59422 16.776C9.59422 20.7025 12.7776 23.8871 16.7053 23.8871ZM34.9098 12.1389C37.6867 14.9164 42.1892 14.9164 44.9661 12.1389C47.743 9.36203 47.743 4.85951 44.9661 2.08262C42.1892 -0.694859 37.6867 -0.694859 34.9098 2.08262C32.1323 4.85951 32.1323 9.36203 34.9098 12.1389ZM56.089 16.7048C56.089 20.632 59.2713 23.816 63.2002 23.816C67.1267 23.816 70.3113 20.632 70.3113 16.7048C70.3113 12.7771 67.1267 9.59373 63.2002 9.59373C59.2713 9.59373 56.089 12.7771 56.089 16.7048ZM67.8354 34.9093C65.0585 37.6862 65.0585 42.1887 67.8354 44.9656C70.6129 47.7425 75.1154 47.7425 77.8917 44.9656C80.6692 42.1887 80.6692 37.6862 77.8917 34.9093C75.1154 32.1318 70.6129 32.1318 67.8354 34.9093ZM63.2701 56.0886C59.343 56.0886 56.159 59.2708 56.159 63.1997C56.159 67.1262 59.343 70.3108 63.2701 70.3108C67.1972 70.3108 70.3812 67.1262 70.3812 63.1997C70.3812 59.2708 67.1972 56.0886 63.2701 56.0886ZM45.0656 67.8349C42.2887 65.058 37.7862 65.058 35.0093 67.8349C32.2324 70.6124 32.2324 75.1149 35.0093 77.8912C37.7862 80.6687 42.2887 80.6687 45.0656 77.8912C47.8431 75.1149 47.8431 70.6124 45.0656 67.8349ZM23.8864 63.2696C23.8864 59.3425 20.703 56.1585 16.7753 56.1585C12.8487 56.1585 9.66415 59.3425 9.66415 63.2696C9.66415 67.1967 12.8487 70.3807 16.7753 70.3807C20.703 70.3807 23.8864 67.1967 23.8864 63.2696ZM12.1394 45.0651C14.9169 42.2883 14.9169 37.7857 12.1394 35.0088C9.36252 32.232 4.86 32.232 2.08311 35.0088C-0.69437 37.7857 -0.69437 42.2883 2.08311 45.0651C4.86 47.8426 9.36252 47.8426 12.1394 45.0651Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5382_269">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function WorkshopIcon({ className }: { className?: string }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_5492_442)">
        <path
          d="M22.1221 6.28997C21.9167 6.4763 21.8016 6.72682 21.8016 6.98774C21.8016 7.24866 21.9167 7.49918 22.1221 7.68552L23.9164 9.28043C24.1261 9.46308 24.4079 9.56538 24.7014 9.56538C24.995 9.56538 25.2768 9.46308 25.4864 9.28043L29.7142 5.52241C30.2781 6.63007 30.4488 7.86418 30.2036 9.06028C29.9585 10.2564 29.309 11.3577 28.3419 12.2173C27.3747 13.077 26.1358 13.6543 24.7902 13.8722C23.4446 14.0902 22.0562 13.9384 20.8101 13.4372L13.061 20.3252C12.6149 20.7218 12.0098 20.9446 11.3789 20.9446C10.748 20.9446 10.1429 20.7218 9.69676 20.3252C9.25063 19.9287 9 19.3908 9 18.83C9 18.2692 9.25063 17.7313 9.69676 17.3348L17.4458 10.4467C16.8819 9.33906 16.7112 8.10494 16.9564 6.90885C17.2015 5.71275 17.851 4.61147 18.8181 3.75178C19.7853 2.89209 21.0242 2.31481 22.3698 2.09688C23.7154 1.87894 25.1038 2.03071 26.3499 2.53195L22.1334 6.28L22.1221 6.28997Z"
          stroke="currentColor"
          stroke-width="2.13126"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.9767 18.3927V23.1288C25.9767 23.7569 25.696 24.3592 25.1964 24.8033C24.6968 25.2474 24.0192 25.4969 23.3126 25.4969H7.32816L2 30.233V11.2885C2 10.6604 2.28068 10.0581 2.78029 9.614C3.2799 9.1699 3.95752 8.92041 4.66408 8.92041H12"
          stroke="currentColor"
          stroke-width="2.66408"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5492_442">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function ConversationsIcon({ className }: { className?: string }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M26.9767 19.5V23.1288C26.9767 23.7569 26.696 24.3592 26.1964 24.8033C25.6968 25.2474 25.0192 25.4969 24.3126 25.4969H8.32816L3 30.233V11.2885C3 10.6604 3.28068 10.0581 3.78029 9.614C4.2799 9.1699 4.95752 8.92041 5.66408 8.92041H11"
        stroke="currentColor"
        stroke-width="2.66408"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 16C25.866 16 29 12.866 29 9C29 5.13401 25.866 2 22 2C18.134 2 15 5.13401 15 9C15 12.866 18.134 16 22 16Z"
        stroke="currentColor"
        stroke-width="2.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 10.7498L22 9.2915V6.6665"
        stroke="currentColor"
        stroke-width="2.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default function AppLayout({
  selected,
  project,
  isLoadingState = false,
  additionalContext = '',
  suggestedQuestions = [],
  children
}: {
  selected: string;
  project?: MendableProject;
  isLoadingState?: boolean;
  additionalContext?: string;
  suggestedQuestions?: string[];
  children: React.ReactNode;
}) {
  const appBaseUrl = '/app' + (project ? `/${project.id}` : '');
  const name = project?.name;
  const company_id = project?.company_id;
  const pricing_plan_id = project?.pricing_plan_id ?? -1;
  const [trialUpgradeWarning, settrialUpgradeWarning] = useState(false);
  const [testModeLoading, setTestModeLoading] = useState(false);

  // const user = useUser();

  const supabaseClient = useSupabaseClient();
  let free_plan_banner_html = null;

  if (isFreePlan(pricing_plan_id)) {
    free_plan_banner_html = (
      // <div className=" bg-white bg-opacity-10 sm:backdrop-blur-sm px-4 py-3 text-white  inset-x-0" style={{ position: 'sticky', top: 0, zIndex: 9999}}>
      <p className="hidden lg:flex text-center text-base font-medium animate-shake2">
        You have the limited free version. Upgrade for more features.
      </p>
      // </div>
    );
  }

  useEffect(() => {
    if (project?.trial_end) {
      const daysLeft = Math.ceil(
        (new Date(project.trial_end).getTime() - Date.now()) / 86400000
      );
      if (daysLeft <= 0 && !trialUpgradeWarning) {
        settrialUpgradeWarning(true);
      }
    }
  }, [project?.trial_end, trialUpgradeWarning]);

  if (project?.trial_end) {
    const daysLeft = Math.ceil(
      (new Date(project?.trial_end).getTime() - Date.now()) / 86400000
    );
    free_plan_banner_html = (
      // <div className=" bg-white bg-opacity-10 sm:backdrop-blur-sm px-4 py-3 text-white  inset-x-0" style={{ position: 'sticky', top: 0, zIndex: 9999}}>
      <p className="text-center text-base font-medium animate-shake2">
        You have {daysLeft} day(s) left in your trial.{' '}
        <a
          className=" underline"
          href="mailto:help@firecrawl.com"
        >
          Talk to us
        </a>
      </p>
      // </div>
    );
  }

  const [sidebarCollapsed, setSidebarCollapsed] = useState(() => {
    // Get the initial state from sessionStorage or default to true
    if (typeof window !== 'undefined') {
      const savedState = sessionStorage.getItem('sidebarCollapsed');
      return savedState ? JSON.parse(savedState) : true;
    }

    return true;
  });

  useEffect(() => {
    // Save the state to sessionStorage whenever it changes
    sessionStorage.setItem(
      'sidebarCollapsed',
      JSON.stringify(sidebarCollapsed)
    );
  }, [sidebarCollapsed]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('sidec');

    // * If we're navigating from outside the dashboard, automatically collapse the sidebar instead of restoring from previous state
    if (router.asPath == '/app') {
      setSidebarCollapsed(true);
    }

    if (myParam === 'true') {
      setSidebarCollapsed(true);
    }
  }, []);

  const redirectToCustomerPortal = async () => {
    try {
      const { url, error } = await postData({
        url: '/api/create-portal-link'
      });
      window.location.assign(url);
    } catch (error) {
      if (error) return alert((error as Error).message);
    }
  };
  const userNavigation = [
    // { name: 'Billing', href: '/app/billing' },
    { name: 'Manage Projects', href: '/app' },
    { name: 'Account', href: '/app/organization/account' },
    {
      name: 'Sign out',
      href: `#`,
      onClick: async () => {
        await supabaseClient.auth.signOut();
        router.push('/signin');
      }
    }
  ];

  // const [feedback, setFeedback] = useRecoilState(feedbackState);

  const bears = useBearStore((state: any) => state.bears);
  interface NavInterface {
    name: string;
    id: string;
    href: string;
    icon: any;
    current: boolean;
    subMenu?: NavInterface[];
    lock_on_prices: number[];
    in_beta?: boolean;
    is_deprecated?: boolean;
  }

  const insight_enabled_emails = [
    'caleb@sideguide.dev',
    'garrett@sideguide.dev',
    'nick@sideguide.dev',
    'eric@sideguide.dev',
    'hello@sideguide.dev'
  ];

  function manageSources() {
    if (project?.is_router)
      return {
        name: '',
        id: '',
        href: `${appBaseUrl}/indexes`,
        icon: FiDatabase,
        current: false,
        lock_on_prices: []
      };
    return {
      name: 'Manage Data Sources',
      id: 'indexes',
      href: `${appBaseUrl}/indexes`,
      icon: FiDatabase,
      current: false,
      lock_on_prices: []
    };
  }

  const navigation: NavInterface[] = [
    // {
    //   name: 'Dashboard',
    //   id: 'dashboard',
    //   href: '${appBaseUrl}',
    //   icon: HomeIcon,
    //   current: true
    // },
    {
      name: 'Quickstart',
      id: 'dashboard',
      href: `${appBaseUrl}`,
      icon: HomeIcon,
      current: false,
      lock_on_prices: []
    },
    {
      name: 'Workshop',
      id: 'playground',
      href: `${appBaseUrl}/playground`,
      icon: WorkshopIcon,
      current: false,
      lock_on_prices: []
    },
    {
      name: 'Tools & Actions',
      id: 'tools',
      href: `${appBaseUrl}/tools`,
      icon: ToolIcon,
      current: false,
      lock_on_prices: [], // TODO insert 27 here after request access period
      in_beta: true
    },
    {
      name: 'Data Sources',
      id: 'indexes_menu',
      href: `${appBaseUrl}/indexes`,
      icon: FiDatabase,
      current: false,
      lock_on_prices: [],
      subMenu: [
        {
          name: 'All Data',
          id: 'indexes',
          href: `${appBaseUrl}/indexes`,
          icon: FiFolder,
          current: false,
          lock_on_prices: []
        },
        {
          name: 'Auto Syncs',
          id: 'autosyncs',
          href: `${appBaseUrl}/autosyncs`,
          icon: FiRefreshCw,
          current: false,
          lock_on_prices: [27]
        }
      ]
    },
    // {
    //   name: 'Tools & Actions',
    //   id: 'tools',
    //   href: `${appBaseUrl}/tools`,
    //   icon: SparklesIcon,
    //   current: false,
    //   lock_on_prices: []
    // },

    // {
    //   name: 'Customize',
    //   id: 'customize',
    //   // icon: MdTune,
    //   icon: VscWand,
    //   href: '#',
    //   current: false,
    //   subMenu: [
    //     {
    //       name: 'Test component',
    //       id: 'customize-appearance',
    //       href: `${appBaseUrl}/customize-appearance`,
    //       current: false
    //     },
    //     {
    //       name: 'Backend Settings',
    //       id: 'customize-settings',
    //       href: `${appBaseUrl}/customize/`,
    //       current: false
    //     }
    //   ]
    // },
    // {
    //   name: 'Customize Model',
    //   id: 'customize-model',
    //   href: `${appBaseUrl}/customize_model`,
    //   icon: BiMessageSquareEdit,
    //   current: false,
    //   lock_on_prices: [],
    {
      name: 'Conversation History',
      id: 'conversations',
      href: `${appBaseUrl}/conversations`,
      icon: ConversationsIcon,
      current: false,
      lock_on_prices: []
    },
    {
      id: 'insights-view',
      name: 'Insights',
      href: `${appBaseUrl}/insights-view`,
      icon: MdInsights,
      current: false,
      lock_on_prices: []
      // in_beta: true
    },
    {
      name: 'Usage',
      id: 'usage',
      href: `${appBaseUrl}/usage`,
      icon: ChartBarIcon,
      current: false,
      lock_on_prices: []
    }
  ];

  const settingsNavigation: NavInterface[] = [
    {
      name: 'API Keys',
      id: 'api-keys',
      href: `${appBaseUrl}/api-keys`,
      icon: KeyIcon,
      current: false,
      lock_on_prices: []
    },
    {
      name: 'Project Settings',
      id: 'settings',
      href: `${appBaseUrl}/settings`,
      icon: MdSettings,
      current: false,
      lock_on_prices: []
    },
    {
      name: 'Manage Projects',
      id: 'projects',
      href: `/app`,
      icon: MdArrowBack,
      current: false,
      lock_on_prices: []
    }
  ];

  const user = useUser();

  if (
    company_id &&
    companies_with_router.includes(company_id) &&
    project.is_router === true
  ) {
    let hiddenSections = {
      id: 'router-view',
      name: 'Router',
      href: `${appBaseUrl}/router-view`,
      icon: TiFlowMerge,
      current: false,
      lock_on_prices: []
    };

    navigation.unshift(hiddenSections);

    // remove manage sources and quickstart
    navigation.splice(1, 1);
    navigation.splice(2, 1);
  }

  // if (company_id && companies_with_tools.includes(company_id)) {
  //   let hiddenSections = {
  //     id: 'tools',
  //     name: 'Tools & Actions',
  //     href: `${appBaseUrl}/tools`,
  //     icon: ToolIcon,
  //     current: false,
  //     lock_on_prices: [],
  //     in_beta: true
  //   };

  //   navigation.splice(2, 0, hiddenSections);

  //   // remove manage sources and quickstart
  //   // navigation.splice(1, 1);
  //   // navigation.splice(2, 1);
  //   // navigation.splice(4, 1);
  // }

  // console.log('user', user)
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // const [openSubMenu, setOpenSubMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [openStates, setOpenStates] = useState({});
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);

  const [testModeDialogOpen, setTestModeDialogOpen] = useState(false);
  const [testModeNewProjectId, setTestModeNewProjectedId] = useState<number>();

  function setTestMode(t: boolean) {
    if (t) {
      //make sure the user is aware of the implications of test mode
      const testModeToast = localStorage.getItem('testModeToast');
      if (!testModeToast) {
        sonnerToast('Switching to test mode', {
          description:
            'If this is the first time you are switching to test mode, it may take a few seconds to generate the test mode project.',
          action: {
            label: 'Got it!',
            onClick: () => console.log('Awesome')
          }
        });
        localStorage.setItem('testModeToast', 'true');
      }
      createTestModeProject();
    } else {
      removeTestModeProject();
    }
  }

  async function removeTestModeProject() {
    if (!project) return;
    router.push('/app/' + project.production_project_id);
  }

  async function createTestModeProject() {
    try {
      if (!project) return;
      setTestModeLoading(true);
      fetch('/api/project/createTestModeProject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          project_id: project.id
        })
      }).then(async (res) => {
        const resJson = await res.json();
        if (resJson.error) return alert(resJson.error);
        setTestModeLoading(false);

        if (resJson.isNew) {
          setTestModeNewProjectedId(resJson.projectId);
          setTestModeDialogOpen(true);
          return;
        }
        // refetch server side props
        router.push('/app/' + resJson.projectId);
      });
    } catch (error) {
      console.error('Error creating test mode project', error);
      setTestModeLoading(false);
    }
  }
  // const handleSubMenuClick = (id:any) => {
  //   if (activeSubMenu === id) {
  //     setActiveSubMenu(null);
  //   } else {
  //     setActiveSubMenu(id);
  //   }
  // };
  const router = useRouter();

  const handleNavigationClick = (href: any) => {
    if (isLoadingState) return;
    router.push(href);
  };

  const projectname =
    project?.name ?? localStorage.getItem('project_name') ?? 'Loading...';

  useEffect(() => {
    if (project) {
      localStorage.setItem('project_name', project.name);
    }
  }, [project]);

  // use some sort of local storage to store the project name
  // if the project name is not in the local storage, then use that as project name, so we prevent the loading message
  // useEffect(() => {
  //   if(localStorage.getItem('project_name') === null){
  //     if (project ) {
  //       localStorage.setItem('project_name', project.name);
  //     }

  //   }

  // }
  // , [project]);
  // const projectname = localStorage.getItem('project_name') ?? project?.name;

  const maxLength = 22;

  const truncateProjectName = (name: string) => {
    return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
  };

  function sendSlackMessage(message: string) {
    fetch('/api/slack/sendMendableMessage', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: 'User email:' + user?.email + message,
        url: 'https://hooks.slack.com/services/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'
      })
    });
  }
  return (
    <div className="text-white relative">
      {/* <MendableFloatingButton
        anon_key="d3e6b1cf-f207-4a79-b211-025d00a81958"
        style={{
          accentColor: '#8559F4',
          darkMode: true,
          backgroundColor: '#030712'
        }}
        welcomeMessage="Hi, I am Copilot, trained only on Mendable's docs. How can I help you?"
        popupText={"Hi, I'm Copilot. How can I help you?"}
        dismissPopupAfter={3}
        hintQuestions={suggestedQuestions}
        dialogPlaceholder="How do I create a copilot like you?"
        context={
          'You are a product copilot for the Mendable team. A user with the following user information will ask you questions\n' +
          'Any questions that specify project details use this information to answer: ' +
          'name: ' +
          project?.name +
          ', ' +
          'id: ' +
          project?.id +
          ', ' +
          'company_id: ' +
          project?.company_id +
          ', ' +
          'support_url: ' +
          project?.support_url +
          ', ' +
          'pricing_plan_id: ' +
          project?.pricing_plan_id +
          ', ' +
          'display_id: ' +
          project?.display_id +
          ', ' +
          'prompt_id: ' +
          project?.prompt_id +
          ', ' +
          'isFaqPublic: ' +
          project?.isFaqPublic +
          ', ' +
          'max_messages_per_month: 10,000' +
          'enforce_whitelist: ' +
          project?.enforce_whitelist +
          ', ' +
          'max_req_per_ip_per_minute: ' +
          project?.max_req_per_ip_per_minute +
          ', ' +
          'whitelisted_domains: ' +
          project?.whitelisted_domains +
          ', ' +
          '------\nMore user context:\n' +
          additionalContext
        }
        onMessageForTracking={(q, a) => {
          // if it is the first or second message, send a slack message
          // check the localstorage to see if the length is 1
          // first message
          // send a slack message
          sendSlackMessage(
            `*Copilot Question:* ${
              q.length > 150 ? q.substring(0, 150) + '...' : q
            } \n\n*Answer:* ${a.length > 150 ? a.substring(0, 150) + '...' : a}`
          );
        }}
        __experimentalBuilderApiKey={project?.server_auth_key}
      /> */}

      {/* <img src="/Frame 543 (5).png" className="fixed" /> */}
      <img
        src="/Astro Gradient (1) (8) 1 (1) 2 (1).png"
        className="fixed opacity-20 h-screen w-full"
      />
      <UpgradeDialog open={openUpgradeDialog} setOpen={setOpenUpgradeDialog} />
      <UpgradeTrialDialog
        open={trialUpgradeWarning}
        setOpen={settrialUpgradeWarning}
      />

      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[40] lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <Link href="/app">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Logo />
                    {/* {CompanyConversations.nam} */}
                  </div>
                </Link>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">
                    {/* Mobile */}
                    {navigation.concat(settingsNavigation).map((item) => (
                      <>
                        <Link
                          href={
                            !isLoadingState &&
                            !item.lock_on_prices.includes(pricing_plan_id)
                              ? item.href
                              : '#'
                          }
                          key={item.href}
                        >
                          <a
                            key={item.name}
                            onClick={() => {
                              posthog.capture('clicked_dashboard_nav_link', {
                                nav_link: item.name
                              });
                              if (
                                item.lock_on_prices.includes(pricing_plan_id)
                              ) {
                                setOpenUpgradeDialog(true);
                              }
                            }}
                            className={classNames(
                              selected === item.id
                                ? 'bg-black text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'group flex items-center rounded-md px-2 py-2 text-base font-medium'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                selected === item.id
                                  ? 'text-gray-300'
                                  : 'text-gray-400 group-hover:text-gray-300',
                                'mr-4 h-6 w-6 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                            {item.lock_on_prices.includes(pricing_plan_id) && (
                              <MdLock
                                className={classNames(
                                  selected === item.id
                                    ? 'text-gray-300'
                                    : 'text-gray-400  transition-all',
                                  'ml-2 justify-self-end h-4 w-4 flex-shrink-0'
                                )}
                                aria-hidden="true"
                              />
                            )}
                          </a>
                        </Link>
                        {item.subMenu &&
                          item.subMenu.map((subItem) => (
                            <Link
                              href={
                                !isLoadingState &&
                                !subItem.lock_on_prices.includes(
                                  pricing_plan_id
                                )
                                  ? subItem.href
                                  : '#'
                              }
                              key={subItem.href}
                            >
                              <a
                                key={subItem.name}
                                onClick={() => {
                                  posthog.capture(
                                    'clicked_dashboard_nav_link',
                                    {
                                      nav_link: subItem.name
                                    }
                                  );
                                  if (
                                    subItem.lock_on_prices.includes(
                                      pricing_plan_id
                                    )
                                  ) {
                                    setOpenUpgradeDialog(true);
                                  }
                                }}
                                className={classNames(
                                  selected === subItem.id
                                    ? 'bg-black text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                  'group flex items-center rounded-md pl-6 pr-2 py-2 text-base font-medium'
                                )}
                              >
                                <subItem.icon
                                  className={classNames(
                                    selected === subItem.id
                                      ? 'text-gray-300'
                                      : 'text-gray-400 group-hover:text-gray-300',
                                    'mr-4 h-6 w-6 flex-shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {subItem.name}
                                {subItem.lock_on_prices.includes(
                                  pricing_plan_id
                                ) && (
                                  <MdLock
                                    className={classNames(
                                      selected === subItem.id
                                        ? 'text-gray-300'
                                        : 'text-gray-400  transition-all',
                                      'ml-2 justify-self-end h-4 w-4 flex-shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                )}
                              </a>
                            </Link>
                          ))}
                      </>
                    ))}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div
        className={`hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col z-50 transition-all ease-in-out backdrop-blur-3xl ${
          sidebarCollapsed ? 'lg:w-[73px]' : 'lg:w-64'
        }`}
        onMouseEnter={() => setSidebarCollapsed(false)}
        onMouseLeave={() => setSidebarCollapsed(true)}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-gray-950/50">
          <div
            className={`flex items-center border-r border-gray-700 px-4 pt-4 ${
              sidebarCollapsed ? 'justify-center' : 'justify-between'
            }`}
          >
            <Link href="/app">
              <div className="flex h-16 flex-shrink-0 items-center justify-center bg-gray-950/50 hover:cursor-pointer">
                <Logo />
              </div>
            </Link>

            {!sidebarCollapsed && (
              <div className=" text-sm flex-shrink-0 text-left flex flex-col items-end">
                <span className="text-gray-500">
                  {project?.is_router ? 'Router' : 'Project'}
                </span>
                <span className="font-bold">
                  {truncateProjectName(projectname)}
                </span>
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav className="flex-1 space-y-1 px-2 py-4 border-r border-gray-700 overflow-x-hidden">
              {navigation.map((item) => (
                <Disclosure as="div" key={item.id} defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        onClick={() => {
                          posthog.capture('clicked_dashboard_nav_link', {
                            nav_link: item.name
                          });
                          if (!item.lock_on_prices.includes(pricing_plan_id)) {
                            !item.subMenu && handleNavigationClick(item.href);
                          } else {
                            // upgrade dialog
                            setOpenUpgradeDialog(true);
                          }
                        }}
                        className={classNames(
                          selected === item.id
                            ? 'bg-gray-800 text-white'
                            : 'text-gray-300 hover:bg-gray-800 hover:text-white',
                          'group flex items-center rounded-md px-2 py-2 text-sm font-medium w-full justify-between focus:outline-none'
                        )}
                      >
                        <div className="flex items-center">
                          <item.icon
                            className={classNames(
                              selected === item.id
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300 group-hover:animate-rotate-hover transition-all',
                              ' h-6 w-6 flex-shrink-0',
                              'mx-2'
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={`transition-opacity duration-500 text-left ${
                              sidebarCollapsed
                                ? 'opacity-0 w-64 min-w-10 max-h-6 whitespace-nowrap'
                                : 'opacity-1 max-w-64 min-w-8 max-h-6 whitespace-nowrap'
                            }`}
                          >
                            {item.name}
                          </span>
                          {item.lock_on_prices.includes(pricing_plan_id) && (
                            <MdLock
                              className={classNames(
                                selected === item.id
                                  ? 'text-gray-300'
                                  : 'text-gray-400  transition-all',
                                'ml-2 justify-self-end h-4 w-4 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          )}
                          {item.in_beta && !sidebarCollapsed && (
                            <div className="ml-2 bg-green-500/25 border border-green-500 text-green-500 rounded px-1">
                              New
                            </div>
                          )}

                          {item.is_deprecated && (
                            <div className="ml-2 bg-yellow-500/40 rounded px-1">
                              Deprecated
                            </div>
                          )}
                        </div>

                        {item.subMenu && (
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 mr-2 transition-transform duration-200`}
                          />
                        )}
                      </Disclosure.Button>
                      {item.subMenu && !sidebarCollapsed && (
                        <Disclosure.Panel className="space-y-1">
                          {item.subMenu.map((subItem) => (
                            <Disclosure.Button
                              onClick={() => {
                                posthog.capture('clicked_dashboard_nav_link', {
                                  nav_link: subItem.name
                                });
                                if (
                                  !subItem.lock_on_prices.includes(
                                    pricing_plan_id
                                  )
                                ) {
                                  !subItem.subMenu &&
                                    handleNavigationClick(subItem.href);
                                } else {
                                  // upgrade dialog
                                  setOpenUpgradeDialog(true);
                                }
                              }}
                              className={classNames(
                                selected === subItem.id
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-300 hover:bg-gray-800 hover:text-white',
                                'group flex items-center rounded-md px-2 pb-2 text-sm font-medium w-full justify-between focus:outline-none'
                              )}
                              key={subItem.href}
                            >
                              <a
                                key={subItem.id}
                                className={classNames(
                                  selected === subItem.id
                                    ? 'bg-gray-800 text-white'
                                    : 'text-gray-300 hover:bg-gray-800 hover:text-white',
                                  'mt-2 group flex items-center rounded-md h-6 px-6 text-sm font-medium cursor-pointer'
                                )}
                              >
                                <subItem.icon
                                  className={classNames(
                                    selected === subItem.id
                                      ? 'text-gray-300'
                                      : 'text-gray-400 group-hover:text-gray-300 group-hover:animate-rotate-hover transition-all',
                                    ' h-6 w-6 flex-shrink-0',
                                    'mx-2'
                                  )}
                                  aria-hidden="true"
                                />
                                {subItem.name}
                                {subItem.lock_on_prices.includes(
                                  pricing_plan_id
                                ) && (
                                  <MdLock
                                    className={classNames(
                                      selected === subItem.id
                                        ? 'text-gray-300'
                                        : 'text-gray-400  transition-all',
                                      'ml-2 justify-self-end h-4 w-4 flex-shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                )}
                                {subItem.in_beta && !sidebarCollapsed && (
                                  <div className="ml-2 bg-green-500/25 border border-green-500 text-green-500 rounded px-1">
                                    New
                                  </div>
                                )}

                                {subItem.is_deprecated && (
                                  <div className="ml-2 bg-yellow-500/40 rounded px-1">
                                    Deprecated
                                  </div>
                                )}
                              </a>
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      )}
                    </>
                  )}
                </Disclosure>
              ))}
            </nav>
            {/* <div className="bottom-0 flex flex-col  justify-center items-center space-y-1 px-2 py-4 border-r border-gray-700 overflow-x-hidden">
            {!sidebarCollapsed && (
                <div className='mx-2'>
                  <div
                    className={`px-2 py-1 text-sm flex items-center rounded-lg gap-1 ${
                      project?.is_test_mode
                        ? 'border border-orange-500 bg-orange-500/25 text-orange-500'
                        : 'border border-gray-800  text-white'
                    }`}
                  >
                    Test Mode
                    <TestModeSwitch
                      checked={project?.is_test_mode}
                      onCheckedChange={(checked) => {
                        setTestMode(checked);
                      }}
                    />
                  </div>
                </div>
              )}
            </div> */}
            <nav className="bottom-0 flex-col space-y-1 px-2 py-4 border-r border-gray-700 overflow-x-hidden">
              <div className="mb-2 mt-6 mx-2 border-t border-gray-700"></div>
              {settingsNavigation.map((item) => (
                <Disclosure
                  as="div"
                  key={item.id}
                  defaultOpen={
                    item.id === selected.split('-')[0].replaceAll('-', '')
                  }
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        onClick={() => {
                          posthog.capture('clicked_dashboard_nav_link', {
                            nav_link: item.name
                          });
                          if (!item.lock_on_prices.includes(pricing_plan_id)) {
                            !item.subMenu && handleNavigationClick(item.href);
                          } else {
                            // upgrade dialog
                            setOpenUpgradeDialog(true);
                          }
                        }}
                        className={classNames(
                          selected === item.id
                            ? 'bg-gray-800 text-white'
                            : 'text-gray-300 hover:bg-gray-800 hover:text-white',
                          'group flex items-center rounded-md px-2 py-2 text-sm font-medium w-full justify-between focus:outline-none'
                        )}
                      >
                        <div className="flex items-center">
                          <item.icon
                            className={classNames(
                              selected === item.id
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300 group-hover:animate-rotate-hover transition-all',
                              ' h-6 w-6 flex-shrink-0',
                              'mx-2'
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={`transition-opacity duration-500 text-left ${
                              sidebarCollapsed
                                ? 'opacity-0 w-64 min-w-10 max-h-6 whitespace-nowrap'
                                : 'opacity-1 max-w-64 min-w-8 max-h-6 whitespace-nowrap'
                            }`}
                          >
                            {item.name}
                          </span>
                          {item.lock_on_prices.includes(pricing_plan_id) && (
                            <MdLock
                              className={classNames(
                                selected === item.id
                                  ? 'text-gray-300'
                                  : 'text-gray-400  transition-all',
                                'ml-2 justify-self-end h-4 w-4 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          )}
                          {item.in_beta && !sidebarCollapsed && (
                            <div className="ml-2 bg-green-500/25 border border-green-500 text-green-500 rounded px-1">
                              New
                            </div>
                          )}

                          {item.is_deprecated && (
                            <div className="ml-2 bg-yellow-500/40 rounded px-1">
                              Deprecated
                            </div>
                          )}
                        </div>

                        {item.subMenu && (
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 mr-2 transition-transform duration-200`}
                          />
                        )}
                      </Disclosure.Button>
                      {item.subMenu && (
                        <Disclosure.Panel className="space-y-1">
                          {item.subMenu.map((subItem) => (
                            <Link
                              href={!isLoadingState ? subItem.href : '#'}
                              key={subItem.href}
                            >
                              <a
                                key={subItem.id}
                                onClick={() =>
                                  handleNavigationClick(subItem.href)
                                }
                                className={classNames(
                                  selected === subItem.id
                                    ? 'bg-gray-800 text-white'
                                    : 'text-gray-300 hover:bg-gray-800 hover:text-white',
                                  'mt-2 group flex items-center rounded-md px-6 py-2 text-sm font-medium cursor-pointer'
                                )}
                              >
                                {subItem.name}
                              </a>
                            </Link>
                          ))}
                        </Disclosure.Panel>
                      )}
                    </>
                  )}
                </Disclosure>
              ))}
            </nav>

            <Menu as="div" className="relative">
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute mb-2 left-1/2 transform -translate-x-1/2 z-10 w-[90%] rounded-md bg-gray-900  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  style={{ bottom: '2.5rem' }}
                >
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          href={
                            !isLoadingState && !item.onClick ? item.href : '#'
                          }
                        >
                          <a
                            onClick={item.onClick ? item.onClick : () => {}}
                            className={classNames(
                              active ? 'bg-gray-800' : '',
                              'block px-4 py-2 text-sm text-gray-300'
                            )}
                          >
                            {item.name}
                          </a>
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
                <div className="mb-4 flex items-center justify-center">
                  {/* <HeroButton color="gray" icon={<MdArrowBack />} href="/app">
                  Manage Projects
                </HeroButton> */}

                  {/* <div className="mt-4 flex items-center justify-center">
                  { sidebarCollapsed ? 
                    <HeroButton icon={<MdArrowBack />} href="/app"></HeroButton> :
                    <HeroButton icon={<MdArrowBack />} href="/app">
                      Manage Projects
                    </HeroButton>
                  }
                </div> */}
                </div>
              </Transition>

              {/* <div>
    <Menu.Button className="my-2 px-2 flex w-full items-center text-sm focus:outline-none focus:ring-0  focus:ring-offset-0">
      <span className="sr-only">Open project selection</span>
      <div className="h-8 w-full flex items-center justify-center bg-gray-700 rounded-full">
        {'Select Project'}
      </div>
    </Menu.Button>
  </div> */}
            </Menu>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:pl-20">
        {project?.is_test_mode && (
          <TestModeBanner text="Test Mode is ON. Changes to this project won't affect production." />
        )}

        <div className="backdrop-blur-sm  sticky top-0 z-10 flex h-16 flex-shrink-0 bg-[#05050a00] shadow">
          <button
            type="button"
            className=" px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 items-center justify-between pl-16">
            {free_plan_banner_html}

            {/* {(project?.pricing_plan_id && !isFreePlan(project?.pricing_plan_id) && !project?.trial_end) &&( */}

            {/* )} */}

            <div className="flex flex-1">
              <form
                className="flex w-full lg:ml-0"
                action="#"
                method="GET"
              ></form>
            </div>
            <div className="mr-2 ml-4 flex items-center lg:ml-6 ">
              {/* <button
                type="button"
                className="rounded-full bg-gray-900 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button> */}
              <TestModeDialog
                onClick={() => {}}
                testModeNewProjectId={testModeNewProjectId ?? -1}
                open={testModeDialogOpen}
                setOpen={(f: boolean) => {
                  if (!f) {
                    router.push('/app/' + testModeNewProjectId);
                  }
                  setTestModeDialogOpen(f);
                }}
              />
              {project?.pricing_plan_id &&
                !isFreePlan(project.pricing_plan_id) &&
                !project.is_router && (
                  <div className="mx-2">
                    <div
                      className={`px-2 py-2 text-sm flex items-center rounded-lg gap-2 ${
                        project?.is_test_mode
                          ? 'border border-orange-500 bg-orange-500/25 text-orange-500'
                          : 'border border-gray-800  text-white'
                      }`}
                    >
                      Test Mode
                      {testModeLoading ? (
                        <CgSpinner className="animate-spin mr-2" size={20} />
                      ) : (
                        <TestModeSwitch
                          checked={project?.is_test_mode}
                          onCheckedChange={(checked) => {
                            setTestMode(checked);
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              {/* Profile dropdown */}
              {project?.pricing_plan_id && isFreePlan(project.pricing_plan_id) && (
                <Button
                  onClick={() => router.push('/pricing?upgrade-from-dash=true')}
                  className={`mr-2 text-sm h-9 ${
                    project?.is_test_mode ? 'bg-orange-500' : 'bg-primary'
                  } border border-gray-800 px-2 py-[2px] hover:bg-opacity-75`}
                >
                  <CgArrowUpR className="mr-2" />
                  Upgrade
                </Button>
              )}
              <Button
                className="mr-2 text-sm h-9 bg-gray-800/40 border border-gray-800 px-2 py-[2px] hover:bg-gray-800"
                onClick={() => {
                  // send email
                  window.open('https://docs.mendable.ai');
                }}
              >
                <CgFileDocument className="mr-2" />
                Docs
              </Button>
              <div className="mr-2">
                <EmbedPopover />
              </div>

              <Button
                className="text-sm hidden sm:flex h-9 bg-gray-800/40 m-0 shrink-0 border border-gray-800 px-2 py-[0px] hover:bg-gray-800"
                onClick={() => {
                  // push to a new tab
                  window.open(
                    `https://mendable.ai/demo/${project?.display_id.toString()}`,
                    '_blank'
                  );
                }}
              >
                <AiOutlineLink className="mr-2" />
                Share Demo
              </Button>
              <div className="mx-2 hidden sm:flex">
                <HelpPopover />
              </div>
              <NotificationsPopover
                testMode={project?.is_test_mode ?? false}
                project_id={project?.id}
                className="mx-2"
              />

              {/* <Button className='ml-2 text-sm h-8 bg-gray-800/40 border border-gray-800 px-2 py-[2px] hover:bg-gray-800' onClick={() => {
                // send email
                window.open('https://discord.gg/kJufGDb7AA');
              }}>
                <MdHelp className='mr-2' />
                Discord

              </Button> */}
              {/* <div className="ml-2 relative">
                <Button
                  className="text-sm h-8 bg-gray-800/40 shrink-0 border border-gray-800 px-2 py-[2px] hover:bg-gray-800"
                  onClick={() => setFeedback(true)}
                >
                  <MdChat className="mr-2" />
                  Give Feedback
                </Button>
                <div className="absolute top-14 z-[10000000000000] right-0">
                  <FeedbackDialog></FeedbackDialog>
                </div>
              </div> */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-black text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <div className="h-9 w-9 flex items-center justify-center bg-gray-700 rounded-full">
                      {user && user.email !== undefined
                        ? user.email[0].toUpperCase()
                        : 'A'}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-gray-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            href={
                              !isLoadingState && !item.onClick ? item.href : '#'
                            }
                          >
                            <a
                              onClick={item.onClick ? item.onClick : () => {}}
                              className={classNames(
                                active ? 'bg-gray-800' : '',
                                'block px-4 py-2 text-sm text-gray-300'
                              )}
                            >
                              {item.name}
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <main className="flex-1">
          <div className="relative py-6 w-full m-w-screen min-h-[80%] h-[80%] ">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}
