export const dataProviders = [
  {
    value: 'oauth',
    label: 'OAuth (Notion, Confluence, etc.)'
  },
  {
    value: 'url',
    label: 'Website (via Crawler)'
  },
  {
    value: 'docusaurus',
    label: 'Docusaurus'
  },
  {
    value: 'github',
    label: 'GitHub'
  },
  
  {
    value: 'openapiyaml',
    label: 'OpenAPI'
  },
  {
    value: 'youtube',
    label: 'YouTube Video'
  },
  {
    value: 'youtube-channel',
    label: 'YouTube Channel Videos'
  },
  {
    value: 'mdpdf_files',
    label: 'Files'
  },
  {
    value: 'sitemap',
    label: 'Website (via Sitemap)'
  },
  
  {
    value: 'zendesk',
    label: 'Zendesk'
  },
  {
    value: 'notion',
    label: 'Notion'
  },
  {
    value: 'slack',
    label: 'Slack'
  },
  {
    value: 'confluence',
    label: 'Confluence'
  },
  {
    value: 'jira',
    label: 'Jira'
  },
  {
    value: 'salesforce',
    label: 'Salesforce'
  },
  {
    value: 'google-drive',
    label: 'Google Drive'
  },
  {
    value: 'text',
    label: 'Text'
  },
  {
    value: 'website-crawler',
    label: 'Website (via Crawler 1.0)'
  },
  {
    value: 'legacy',
    label: 'Legacy'
  },
  {
    value: 'manual',
    label: 'Manual'
  },
  {
    value: 'github-app',
    label: 'Private Github'
  },
  {
    value: 'qna',
    label: 'Question & Answer'
  },
  {
    value: 'video',
    label: 'Video'
  }
];
