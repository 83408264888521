import React from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { HomeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  BellIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/20/solid';
import Logo from '@/components/icons/Logo';
import { VscWand } from 'react-icons/vsc';

import { FiDatabase } from 'react-icons/fi';
import {
  Bars3BottomLeftIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  InboxIcon,
  UsersIcon,
  KeyIcon,
  ChatBubbleLeftRightIcon
} from '@heroicons/react/24/outline';
import {
  MdChat,
  MdDashboard,
  MdDashboardCustomize,
  MdDataUsage,
  MdElectricBolt,
  MdEmail,
  MdHelp,
  MdInsights,
  MdOutlet,
  MdPanTool,
  MdPeople,
  MdPerson,
  MdSettings,
  MdTune
} from 'react-icons/md';
import { GrEdit, GrIntegration } from 'react-icons/gr';
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';
import router, { useRouter } from 'next/router';
import Link from 'next/link';
import { MendableProject } from 'types';
import { Button } from './ui/button';
import FeedbackDialog from './FeedbackButton';
import { feedbackState } from '../atoms/feedback';
import { useRecoilState } from 'recoil';
import { CgArrowUpR, CgFileDocument } from 'react-icons/cg';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { IoLogoDiscord } from 'react-icons/io5';
import HelpPopover from './HelpPopover';
import Banner from '../Banner';
import { Unplug } from 'lucide-react';



function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function ChooseProjectsLayout({
  selected,
  project,
  children
}: {
  selected: string;
  project?: MendableProject;
  children: React.ReactNode;
}) {
  const supabaseClient = useSupabaseClient();
  const userNavigation = [

    // { name: 'Settings', href: '${appBaseUrl}/settings' },
    { name: 'Account', href: '/app/organization/account' },
    { name: 'Sign out', href: `#`, onClick: async () => {
      await supabaseClient.auth.signOut()
      router.push('/signin')
    } }
  ];
  
  const [feedback, setFeedback] = useRecoilState(feedbackState);

  const navigation = [
    {
      name: 'Projects',
      id: 'projects',
      href: `/app`,
      icon: MdDashboard,
      current: false
    },
    {
      name: 'Team',
      id: 'team',
      href: `/app/organization/team`,
      icon: MdPeople,
      current: false
    },
    // {
    //   name: 'Connections',
    //   id: 'connections',
    //   href: `/app/user/connections`,
    //   icon: Unplug,
    //   current: false
    // },
    // {
    //   name: 'Payment History',
    //   id: 'total-usage',
    //   href: `/app/organization/total-usage`,
    //   icon: ChartBarIcon,
    //   current: false,
    // },
    {
      name: 'Account',
      id: 'account',
      href: `/app/organization/account`,
      icon: MdPerson,
      current: false,
    },
  ];
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [openStates, setOpenStates] = useState({});

  // const handleSubMenuClick = (id:any) => {
  //   if (activeSubMenu === id) {
  //     setActiveSubMenu(null);
  //   } else {
  //     setActiveSubMenu(id);
  //   }
  // };
  const router = useRouter();

  const user = useUser();

  const handleNavigationClick = (href: any) => {
    router.push(href);
  };
  const handleSubMenuClick = (id: any) => {
    // console.log('id', id);
    if (openSubMenu === id) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(id);
    }
  };

  return (
    <div className="text-white relative">
      
      {/* <img src="/Frame 543 (5).png" className="fixed" /> */}
      <img
        src="/Astro Gradient (1) (8) 1 (1) 2 (1).png"
        className="fixed opacity-25 h-screen w-full"
      />

      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[40] lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                <Link href="https://mendable.ai">
                  <a className=''>
                  <Logo />
                  </a>
                  </Link>
                  
                  {/* {CompanyConversations.nam} */}
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">
                    {/* Mobile */}
                    {navigation.map((item) => (
                      <Link href={item.href} key={item.href}>
                        <a
                          key={item.name}
                          className={classNames(
                            selected === item.id
                              ? 'bg-black text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center rounded-md px-2 py-2 text-base font-medium'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              selected === item.id
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 h-6 w-6 flex-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </Link>
                    ))}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:inset-y-0  lg:fixed lg:flex lg:w-64 lg:flex-col">
      
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div style={{}} className="flex min-h-0 flex-1 flex-col bg-gray-950/50">
          <div className="flex h-16 flex-shrink-0 items-center justify-between bg-gray-950/50 px-4  border-r border-gray-700">
          <Link href="https://mendable.ai">
            <a className=''>
                  <Logo />
                  </a>
                  </Link>
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav className="flex-1 space-y-1 px-2 py-4 border-r border-gray-700">
              {navigation.map((item) => (
                <Disclosure
                  as="div"
                  key={item.id}
                  defaultOpen={
                    item.id === selected.split('-')[0].replaceAll('-', '')
                  }
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        onClick={() =>
                          //@ts-ignore
                          !item.subMenu && handleNavigationClick(item.href)
                        }
                        className={classNames(
                          selected === item.id
                            ? 'bg-gray-800 text-white'
                            : 'text-gray-300 hover:bg-gray-800 hover:text-white',
                          'group flex items-center rounded-md px-2 py-2 text-sm font-medium w-full justify-between focus:outline-none'
                        )}
                      >
                        <div className="flex items-center">
                          <item.icon
                            className={classNames(
                              selected === item.id
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300 group-hover:animate-rotate-hover transition-all',
                              'mr-3 h-6 w-6 flex-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>

                        {
                          //@ts-ignore
                          item.subMenu && (
                            <ChevronDownIcon
                              className={`${
                                open ? 'transform rotate-180' : ''
                              } w-5 h-5 mr-2 transition-transform duration-200`}
                            />
                          )
                        }
                      </Disclosure.Button>
                      {/* {item.subMenu && (
                        <Disclosure.Panel className="space-y-1">
                          {item.subMenu.map((subItem) => (
                            <Link href={subItem.href}>
                              <a
                                key={subItem.id}
                                onClick={() =>
                                  handleNavigationClick(subItem.href)
                                }
                                className={classNames(
                                  selected === subItem.id
                                    ? 'bg-gray-800 text-white'
                                    : 'text-gray-300 hover:bg-gray-800 hover:text-white',
                                  'mt-2 group flex items-center rounded-md px-6 py-2 text-sm font-medium cursor-pointer'
                                )}
                              >
                                {subItem.name}
                              </a>
                            </Link>
                          ))}
                        </Disclosure.Panel>
                      )} */}
                    </>
                  )}
                </Disclosure>
              ))}
            </nav>
            <Menu as="div" className="relative">
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute mb-2 left-1/2 transform -translate-x-1/2 z-10 w-[90%]  rounded-md bg-gray-900  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  style={{ bottom: '2.5rem' }}
                >
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link href={item.href}>
                          <a
                            onClick={item.onClick ? item.onClick : () => {}}
                            className={classNames(
                              active ? 'bg-gray-800' : '',
                              'block px-4 py-2 text-sm text-gray-300'
                            )}
                          >
                            {item.name}
                          </a>
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
              {/* <div>
    <Menu.Button className="my-2 px-2 flex w-full items-center text-sm focus:outline-none focus:ring-0  focus:ring-offset-0">
      <span className="sr-only">Open project selection</span>
      <div className="h-8 w-full flex items-center justify-center bg-gray-700 rounded-full">
        {'Select Project'}
      </div>
    </Menu.Button>
  </div> */}
            </Menu>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:pl-64">
      {/*<Banner text='We will be down for maintenance on Saturday from 9am-12pm ET (12/9/23)' />*/}
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-[#05050a00] shadow">
          <button
            type="button"
            className="border-r border-black px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4 backdrop-blur-sm">
            <div className="flex flex-1">
              <form
                className="flex w-full lg:ml-0"
                action="#"
                method="GET"
              ></form>
            </div>
            <div className="ml-4 flex items-center lg:ml-6">
              {/* <button
                type="button"
                className="rounded-full bg-gray-900 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button> */}
              <Button onClick={() => router.push("/pricing?upgrade-from-dash=true")} className='mr-2 text-sm h-8 bg-primary border border-gray-800 px-2 py-[2px] hover:bg-opacity-75'>
              
              <CgArrowUpR  className='mr-2'/>
                Upgrade

              </Button>
              <Button className=' text-sm h-8 bg-gray-800/40 border border-gray-800 px-2 py-[2px] hover:bg-gray-800' onClick={()=> {
                // send email
                window.open('https://docs.mendable.ai');
              }}>
              <CgFileDocument  className='mr-2'/>
                Docs

              </Button>
              <div className='ml-2'><HelpPopover /></div>
              
<div className='ml-2 relative hidden sm:flex'>
              <Button className='text-sm h-8 bg-gray-800/40 border shrink-0 border-gray-800 px-2 py-[2px] hover:bg-gray-800' onClick={()=> setFeedback(true)}>
              <MdChat  className='mr-2'/>
                Give Feedback

              </Button>
              <div className='absolute top-14 z-[10000000000000] right-0'>
              <FeedbackDialog ></FeedbackDialog>
              </div>

              </div>
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-black text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <div className="h-8 w-8 flex items-center justify-center bg-gray-700 rounded-full">
                      {user && user.email !== undefined
                        ? user.email[0].toUpperCase()
                        : 'A'}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-gray-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link href={item.href}>
                            <a 
                            onClick={item.onClick ? item.onClick : () => {}}
                              className={classNames(
                                active ? 'bg-gray-800' : '',
                                'block px-4 py-2 text-sm text-gray-300'
                              )}
                            >
                              {item.name}
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 ">
          
          <div className="relative py-6  w-full m-w-screen min-h-screen h-full ">
            
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}
