import { DateFilter, Message } from '@/types';

export function getFirstSentence(message: string): string {
  const match = message.match(/.+?(?:\.|!|\?)/);
  return match ? match[0] : message;
}

export function tagDuplicateFirstSentences(messages: Message[]): Message[] {
  let sentenceMap: { [sentence: string]: boolean } = {};

  for (let i = 0; i < messages.length; i++) {
    const firstSentence = getFirstSentence(messages[i].message);
    if (sentenceMap[firstSentence]) {
      messages[i].message = 'Duplicate message: ' + messages[i].message;
    } else {
      sentenceMap[firstSentence] = true;
    }
  }
  return messages;
}

export function groupMessagesInPairs(messages: Message[]) {
  messages = tagDuplicateFirstSentences(messages);
  const pairedMessages = [];
  for (let i = 0; i < messages.length; i += 2) {
    pairedMessages.push([messages[i], messages[i + 1]]);
  }
  return pairedMessages;
}

export function formatHumanReadable(timestamp: string, useZ: boolean = true): string {
  // Create a date object in UTC
  const utcDate = new Date(timestamp + (useZ ? 'Z' : '')); // Adding "Z" specifies that the time is in UTC

  // Format date and time components
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Gets the user's time zone
  }).format(utcDate);

  // if date is today, only show time
  const today = new Date();

  if (
    utcDate.getDate() === today.getDate() &&
    utcDate.getMonth() === today.getMonth() &&
    utcDate.getFullYear() === today.getFullYear()
  ) {
    return 'Today at ' + formattedDate.split(',')[1];
  }

  // if date is yesterday, show "Yesterday"

  const yesterday = new Date();

  yesterday.setDate(yesterday.getDate() - 1);

  if (
    utcDate.getDate() === yesterday.getDate() &&
    utcDate.getMonth() === yesterday.getMonth() &&
    utcDate.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Yesterday';
  }

  // tomorrow
  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  if (
    utcDate.getDate() === tomorrow.getDate() &&
    utcDate.getMonth() === tomorrow.getMonth() &&
    utcDate.getFullYear() === tomorrow.getFullYear()
  ) {
    return 'Tomorrow at '+ formattedDate.split(',')[1];
  }

  // otherwise dont show time
  return formattedDate.split(',')[0];
}


export function formatHumanReadableJustTime(timestamp: string, useZ: boolean = true): string {
  if (!timestamp) {
    return '';
  }
  // Create a date object in UTC
  const utcDate = new Date(timestamp + (useZ ? 'Z' : '')); // Adding "Z" specifies that the time is in UTC

  // Format date and time components
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Gets the user's time zone
  }).format(utcDate);

  // just time
  
  return formattedDate.split(',')[1];
}

export function mapFilterToStartEndDate(filter: DateFilter) {
  let start_date = new Date();
  let end_date = new Date();
  switch (filter) {
    case DateFilter.TODAY:
      start_date.setDate(start_date.getDate() - 1);
      break;
    case DateFilter.LAST7DAYS:
      start_date.setDate(start_date.getDate() - 7);
      break;
    case DateFilter.LAST30DAYS:
      start_date.setDate(start_date.getDate() - 30);
      break;
    case DateFilter.LAST60DAYS:
      start_date.setDate(start_date.getDate() - 60);
      break;
    case DateFilter.LAST90DAYS:
      start_date.setDate(start_date.getDate() - 90);
      break;
    case DateFilter.LAST180DAYS:
      start_date.setDate(start_date.getDate() - 180);
      break;
    case DateFilter.ALLTIME:
      start_date = new Date(0);
      break;
  }
  return { start_date, end_date };
}