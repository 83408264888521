import { atom } from "recoil";

export const feedbackState = atom({
  key: 'feedbackState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const feedbackSubmittedState = atom({
  key: 'feedbackSubmittedState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});