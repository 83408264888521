import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Button } from './ui/button';
import { MdCloudDone, MdDone, MdDoneAll, MdError, MdErrorOutline, MdNotifications } from 'react-icons/md';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  ContextNotifications,
  FinalContextNotification,
  TaskStatus
} from '@/types';
import { LoadingIcon } from './insights/LoadingIcon';
import { Grid } from 'react-loader-spinner';
import Link from 'next/link';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Markdown } from '../ui/Messages/Markdown';
import { useRefreshAppLayoutStore } from '@/lib/zustand/refreshAppLayout';
import { toast as sonnerToast } from "sonner"
import { dataProviders } from './indexes/data-providers';
import { BsDatabaseCheck } from 'react-icons/bs';
import { formatHumanReadable } from '@/lib/app/conversations/utils';


export default function NotificationsPopover({
  className,
  project_id,
  testMode,
}: {
  className?: string;
  project_id: number | undefined;
  testMode: boolean;
}) {
  const [contextNotifications, setContextNotifications] = useState<
    ContextNotifications[]
  >([]);
  const [displayNotifications, setDisplayNotifications] = useState<
    FinalContextNotification[]
  >([]);

  const timerIdRef = useRef<any>();
  const [firstPass, setFirstPass] = useState(false);

  const [viewError, setViewError] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!project_id) {
      return;
    }
    fetch('/api/contextsNotifications/get', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        project_id: project_id
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setContextNotifications(data);
      });
  }, [project_id, useRefreshAppLayoutStore.getState().isRefreshed]);

  async function checkTaskStatus(taskId: string) {
    const response = await fetch(
      `${process.env.JS_SCRAPER_SERVER_URL}/checkStatus/${taskId}`
    );
    let status = (await response.json()) as TaskStatus;

    status.taskStatus = status.status;

    if (status.taskStatus === 'completed') {
      try {
        clearInterval(timerIdRef.current[taskId]);
        // sonnerToast.success("Data Ingestion Completed");
      } catch (e) {}
    }

    setDisplayNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.job_id === taskId
          ? { ...notification, ...status }
          : notification
      )
    );
    return status as TaskStatus;
  }

  // clean all the intervals
  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        timerIdRef.current.forEach((timerId: any) => {
          clearInterval(timerId);
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!project_id && contextNotifications.length === 0) {
      return;
    }

    const updateNotificationsStatus = async () => {
      // Only consider the latest 15 notifications for status check
      const latestNotifications = contextNotifications.slice(0, 15);
      const updatedNotifications = await Promise.all(
        latestNotifications.map(async (contextNotification) => {
          const taskStatus = await checkTaskStatus(contextNotification.job_id);
          return {
            ...taskStatus,
            ...contextNotification,
            context_status: contextNotification.status,
            task_status: taskStatus.status
          };
        })
      );
      setDisplayNotifications(updatedNotifications);
      setFirstPass(true);
    };

    updateNotificationsStatus();
  }, [contextNotifications, project_id]);

  useEffect(() => {
    if (!firstPass) {
      return;
    }

    const activeContextNotifications = displayNotifications.filter(
      (contextNotification: ContextNotifications) =>
        contextNotification.status === 'running' && contextNotification.display
    );

    if (activeContextNotifications.length > 0) {
      // console.log("Setting a timer...")
      const checkInterval = 3500; // Check every 5 seconds
      timerIdRef.current = activeContextNotifications.map(
        (contextNotification: ContextNotifications) => {
          return setInterval(() => {
            checkTaskStatus(contextNotification.job_id);
          }, checkInterval);
        }
      );
    }
    setFirstPass(false);
    // This ensures the effect runs once the displayNotifications are set initially
  }, [firstPass, displayNotifications]);

  function sortByDate(displayNotifications: FinalContextNotification[]) {
    return displayNotifications.sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
  }

  function filterDisplayTrue(displayNotifications: FinalContextNotification[]) {
    return displayNotifications.filter(
      (notification) => notification.display === true
    );
  }

  const sortedDisplayNotifications = useMemo(() => {
    return filterDisplayTrue(sortByDate(displayNotifications));
  }, [displayNotifications]);

  function getLatestActiveNotification() {
    const runningNotifications = filterDisplayTrue(displayNotifications).filter(
      (notification) => notification.context_status === 'running'
    );
    if (runningNotifications.length === 0) return { current: 101, total: 100 };

    const notification = runningNotifications.reduce((prev, current) =>
      new Date(prev.created_at) > new Date(current.created_at) ? prev : current
    );

    // Adjusting the progress based on the step
    let adjustedProgress = notification.current;
    const progressFraction = notification.current / notification.total; // 0.5
    switch (notification.current_step) {
      case 'SRAPING':
        adjustedProgress = Math.round((progressFraction / 3)*100);  
        break;
      case 'EMBEDDING':
        adjustedProgress = Math.round(33 + ((progressFraction / 3) * 100));
        break;
      case 'TRAINING':
      case 'SAVING':
        adjustedProgress = Math.round(66 + ((progressFraction / 3)* 100)); // Adjusted to ensure it sums up to 100
        break;
      default:
        adjustedProgress = Math.round(progressFraction * 100);
    }

    return { ...notification, current: adjustedProgress, total: 100 };
  }
  function displayCurrentStep(currentStep: string) {
    if (currentStep === 'SRAPING') {
      return 'Scraping';
    }
    if (currentStep === 'EMBEDDING') {
      return 'Embedding';
    }
    if (currentStep === 'TRAINING') {
      return 'Training';
    }
    if (currentStep === 'SAVING') {
      return 'Training';
    }
  }

  function clear() {
    fetch('/api/contextsNotifications/clear', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        project_id: project_id
      })
    })
      .then((response) => response.json())
      .then((data) => {});
  }
  // const bears = useIngestionStore((state) => state);
  return (
    <div className={className}>
      <Dialog open={viewError} onOpenChange={setViewError}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Ingestion Error</DialogTitle>
            <p className="text-gray-400 text-sm">
              The following error was encountered during ingestion. If it is an
              internal error, please contact help@mendable.ai.
            </p>
          </DialogHeader>

          <div className="chunkscroll max-h-[550px] overflow-y-auto">
            <Markdown str={'Error: ' + error} />
          </div>
        </DialogContent>
      </Dialog>
      <Popover>
        <PopoverTrigger asChild>
          <div className="relative flex items-center justify-center hover:cursor-pointer">
            {getLatestActiveNotification()?.current !== 101 && <div className="absolute  animate-pulse rounded-full bg-purple-600/50 w-12 h-12 z-10"></div>}
            {getLatestActiveNotification()?.current !== 101 &&  <div className="absolute  animate-pulse rounded-full bg-purple-600/30 w-14 h-14 z-10 delay-150"></div>}

            <div className={`absolute w-10 h-10 z-30`}>
              <CircularProgressbar
                className="z-20 "
                value={
                  (getLatestActiveNotification()?.current /
                    getLatestActiveNotification()?.total) *
                  100
                }
                text={``}
                styles={{ path: { stroke: testMode ? `#f97316` : `#8559F4` }, trail: { stroke: '#FFFFFF', opacity: 0.1} }}
              />
            </div>
            <Button
              variant="outline"
              className={`z-30 text-sm rounded-full h-8 bg-gray-950 border border-transparent px-2 py-[2px] hover:bg-gray-800 ring-0 hover:ring-0 focus:ring-0 ring-offset-0 `}
            >
              <MdNotifications size={16} />
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent className="mr-10  !p-0 mt-2 w-[420px] hover:ring-0 ring-offset-0 max-h-[700px] overflow-y-auto bg-gray-900 border-2 border-gray-800 ring-0 outline-none">
          <div className="w-full">
            <div className="fixed z-10 bg-gray-800 overflow-hidden px-4 py-2 w-[410px] flex justify-between items-center">
              <div className="flex justify-start items-center gap-2">
                <MdNotifications size={18} className="text-gray-400" />
                <div className="text-base flex items-center gap-2">Notifications
                <span className="px-1 py-[1px] h-5 text-[11px] flex items-center justify-center rounded font-semibold bg-primary text-white">
                NEW
              </span>
                </div>
              </div>
              <Button
                onClick={() => {
                  setDisplayNotifications([]);
                  clear();
                  sonnerToast("Notifications Cleared",{
                    description: "If you had any active ingestion jobs, they will still continue in the background."
                  });
                }}
                className="h-8 bg-gray-900/40 hover:ring-0 focus:ring-0 ring-0 ring-offset-0 border border-gray-700 text-sm text-gray-100"
              >
                Clear
              </Button>
            </div>
            <div className="pt-12 flex flex-col">
              {displayNotifications &&
                displayNotifications.length > 0 &&
                sortedDisplayNotifications.map((notification) => {
                  return (
                    <Link href={`/app/${project_id}/indexes`}>
                      <a className="hover:cursor-pointer group hover:bg-gray-700/25">
                        <div
                          key={notification.id}
                          className="relative w-full py-6 border-b border-gray-800 px-4 "
                        >
                          <div className="flex justify-start items-center gap-4">
                            {notification.context_status === 'running' ? (
                              <div className="flex flex-col items-center justify-center">
                                <Grid
                                  height={28}
                                  width={28}
                                  radius={5}
                                  color={'#fff'}
                                  ariaLabel="grid-loading"
                                  ms-visible={true}
                                />
                              </div>
                            ) : notification.status === 'failed' ? (
                              <MdErrorOutline size={26} />
                            ) : (
                              <BsDatabaseCheck size={26} className="text-gray-300" />
                            )}
                            <div className="flex flex-col">
                              {notification.context_status === 'running' ? (
                                <p className="text-sm">Adding Data Source</p>
                              ) : notification.status === 'failed' ? (
                                <p className="text-sm">
                                  Error ingesting via{' '}
                                  {notification.ingestion_type}
                                </p>
                              ) : (
                                <p className="text-sm">
                                  Ingestion Completed
                                  {/* {notification.url && !notification.url.startsWith('#')
                                    ? notification.url.length > 20
                                      ? `${notification.url.substring(
                                          0,
                                          17
                                        )}...`
                                      : notification.url
                                    : ''} */}
                                </p>
                              )}
                              {notification.context_status === 'running' && (
                                <div className="text-xs text-gray-400 flex">
                                  {displayCurrentStep(
                                    notification.current_step
                                  )}{' '}
                                  {notification.current}/{notification.total}{notification.current_url && notification.current_url !== "" ? ' - ' : ""}
                                  {notification.current_url && notification.current_url !== "" && notification.current_url.length > 32
                                    ? `${notification.current_url.substring(
                                        0,
                                        32
                                      )}...`
                                    : notification.current_url}
                                </div>
                              )}
                              {notification.context_status === 'completed' && (
                                <div className="text-xs text-gray-400">
                                  Data sources of type '
                                  {dataProviders.find((e) => e.value === notification.ingestion_type)?.label}' were added
                                </div>
                              )}
                              {notification.context_status === 'failed' && (
                                <div className="text-xs text-gray-400">
                                  {notification.data?.error}
                                </div>
                              )}
                              
                            </div>
                            {notification.context_status === 'failed' && (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if(notification.data?.errorMessage) {
                                      setError(notification.data.errorMessage);
                                      setViewError(true);
                                    }
                                  }}
                                  className="mt-2 h-8 w-24 text-xs bg-gray-700/75 border-2 border-gray-700 text-white"
                                >
                                  View Error
                                </Button>
                              )}
                          </div>
                          <div className="absolute bottom-1 right-1 text-[10px] text-gray-500">
                            {notification.created_at &&
                              (() => {
                                return formatHumanReadable(notification.created_at);
                                // const createdAtUTC = new Date(
                                //   notification.created_at + 'Z' // Adjust for timezone offset from UTC
                                // );
                                // const createdAtLocal = new Date(
                                //   createdAtUTC.getTime() + createdAtUTC.getTimezoneOffset() * 60000
                                // );
                                // const today = new Date();
                                // today.setHours(0, 0, 0, 0); // Reset today to start of day for comparison
                                // const yesterday = new Date(today);
                                // yesterday.setDate(yesterday.getDate() - 1);

                                // if (
                                //   createdAtLocal >= today
                                // ) {
                                //   return createdAtLocal.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                                // } else if (
                                //   createdAtLocal < today && createdAtLocal >= yesterday
                                // ) {
                                //   return 'Yesterday';
                                // } else {
                                //   return createdAtLocal.toLocaleDateString();
                                // }
                              })()}
                          </div>
                        </div>
                      </a>
                    </Link>
                  );
                })}

              {sortedDisplayNotifications.length === 0 && (
                <div className="mt-10 mb-8 w-full p-4 text-center">
                  <div className="text-sm text-gray-400">No new notifications</div>
                </div>
              )}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
