import React from 'react';
import { Fragment } from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'

export default function Fence({ children, language }: { children: string, language: any }) {
    return (
      <Highlight
        {...defaultProps}
        code={children.trimEnd()}
        language={language}
        theme={undefined}
      >
        {({ className, style, tokens, getTokenProps }) => (
          <div className={className} style={style}>
            <div>
              {tokens.map((line, lineIndex) => (
                <Fragment key={lineIndex}>
                  {line
                    .filter((token) => !token.empty)
                    .map((token, tokenIndex) => (
                      <span key={tokenIndex} {...getTokenProps({ token })} />
                    ))}
                  {'\n'}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </Highlight>
    )
  }