import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil';
import { feedbackState, feedbackSubmittedState } from '../atoms/feedback';
import posthog from 'posthog-js'
import { useToast } from './ui/use-toast';

export default function FeedbackDialog() {
  const [feedback, setFeedback] = useState("");
  const [showFeedback, setShowFeedback] = useRecoilState(feedbackState);


  // thanks for feedback
  const [feedbackSubimtted, setFeedbackSubmitted] = useRecoilState(feedbackSubmittedState);

  const trigger = useRef(null);
  const feedbackBox = useRef(null);
  const {toast} = useToast();

  const [feeedbackEmoji, setFeedbackEmoji] = useState(-1);

  useOnClickOutside(feedbackBox, () => setShowFeedback(false))

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (!showFeedback || keyCode !== 27) return;
      setShowFeedback(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const reactionMap = [
    "amazing",
    "good",
    "not good",
  ];

  function submitFeedback() {
    if (feedback !== "") {
        posthog.capture('give feedback', {
            feedback: feedback,
            url: window.location.href,
            feedbackEmojiIndex: feeedbackEmoji,
            feedbackReaction : reactionMap[feeedbackEmoji]
        })
      
      setFeedbackSubmitted(true);
      setShowFeedback(false);

      toast({
        title: "Thanks for your feedback!",
      })
      console.log("set thanks")
    }
  }
  return (
    <>
    {showFeedback && (
      <div
        ref={feedbackBox}
        className="bg-gray-900 p-3 rounded-md shadow-lg z-[10000000]"
      >
        <div className="flex items-center justify-between">
          <label htmlFor="feedback" className='text-white text-md '>How can we improve?</label>
          <button
            onClick={() => setShowFeedback(!showFeedback)}
            className="p-2 text-white"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 20 20"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col space-y-2 mt-2">
          <textarea
            id="feedback"
            onChange={(e: any) => setFeedback(e.target.value)}
            placeholder="I love Mendable <3"
            className="p-2 h-24 text-sm w-64 border border-gray-800 bg-gray-700 rounded-md"
          ></textarea>
          <div className="flex items-center justify-between pt-2">
            <div className="flex gap-3">
              <button className={`border ${feeedbackEmoji === 0 ? "border-newpurple p-1 rounded-full animate-bounce" : "border border-transparent animate-none"} transition-all `} type="button" onClick={()=>setFeedbackEmoji(0)}>
                <img alt="emoji" decoding="async" height="20" src="https://assets.vercel.com/twemoji/1f929.svg" width="20" />
              </button>
              <button className={`border ${feeedbackEmoji === 1 ? "border-newpurple p-1 rounded-full animate-bounce" : "border border-transparent animate-none"} transition-all `} type="button" onClick={()=>setFeedbackEmoji(1)}>
                <img alt="emoji" decoding="async" height="20" src="https://assets.vercel.com/twemoji/1f600.svg" width="20" />
                </button>
                <button className={`border ${feeedbackEmoji === 2 ? "border-newpurple p-1 rounded-full animate-bounce" : "border border-transparent animate-none"} transition-all `} type="button" onClick={()=>setFeedbackEmoji(2)}>
                    <img alt="emoji" decoding="async" height="20" src="https://assets.vercel.com/twemoji/1f615.svg" width="20" />
                </button>
            </div>
            <button
              className="p-2 ml-auto bg-primary rounded-lg text-sm bg-newpurple hover:bg-newpurple shadow-md hover:shadow-lg text-white"
              onClick={() => submitFeedback()}
            >
              Send Feedback
            </button>
          </div>
        </div>
      </div>
    )
    }
    </>
  )
  
}



// Hook
export function useOnClickOutside(ref:any, handler:any) {
  useEffect(
    () => {
      const listener = (event :any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}