import Head from 'next/head';
import { useRouter } from 'next/router';
import Navbar from 'components/ui/Navbar';
import Footer from 'components/ui/Footer';
import { ReactNode, useEffect } from 'react';
import { PageMeta } from '../types';
import { useUser } from '@supabase/auth-helpers-react';
import posthog from 'posthog-js';
import { useRecoilState } from 'recoil';
import { referralState } from './atoms/ref';
import React from 'react';
import PageMetaContext from '@/lib/context/metaContext';

interface Props {
  children: ReactNode;
  meta?: PageMeta;
}

export default function Layout({ children, meta: pageMeta }: Props) {
  const router = useRouter();
  const dmeta = {
    title: 'Mendable',
    description:
      'Mendable allows you to easily build AI chat applications. Ingest, customize, then deploy with one line of code anywhere you want. Brought to you by SideGuide',
    // cardImage: '/og.png',
    ogImage: "https://mendable.ai/mendable_new_og1.png",
    ...pageMeta
  };


  const pageMetaFromContext = React.useContext(PageMetaContext);
  const meta = { ...dmeta, ...(pageMeta ?? {}), ...(pageMetaFromContext ?? {}) };


  

  // function dontIncludeNav(url: string){
  // const dontInclude = ['/karate-docs', '/search/embed/activepieces','/search/embed/test','/app/*','/onboarding/*','/app', '/demo/*','/demo', '/search/mintlify','/search/embed/activepieces2', '/test_logo', '/search/twitter', '/playgrounds/*','/search/finpace','/search/plaid','/search/gitlab']
  function dontIncludeNav(url: string) {
    const dontInclude = [
      '/karate-docs',
      '/search/embed/activepieces',
      '/search/embed/test',
      '/app/*',
      '/app',
      '/demo/*',
      '/demo',
      '/projects/*',
      '/onboarding/*',
      '/search/mintlify',
      '/search/embed/activepieces2',
      '/test_logo',
      '/search/twitter',
      '/playgrounds/*',
      '/search/finpace',
      '/search/plaid',
      '/search/gitlab',
      '/search/anyscale',
      '/search/newstore',
      '/search/geoguessr',
      '/mendable_project/*',
      '/demos/*',
      '/demos',
      '/geoguessr',
      '/geoguessr/*',
      '/demo/*',
      '/test-demo'
    ];
    // Be able to handle wildcards like /dashboard/*
    for (let i = 0; i < dontInclude.length; i++) {
      if (dontInclude[i].includes('*')) {
        const newDontInclude = dontInclude[i].replace('*', '');
        if (router.pathname.includes(newDontInclude)) {
          return true;
        }
      }
      if (router.pathname === dontInclude[i]) {
        return true;
      }
    }

    return dontInclude.includes(url);
  }

  let user = useUser();

  const [refState, setRefState] = useRecoilState(referralState);
  useEffect(() => {
    if (user) {
      posthog?.identify(user.id, {
        email: user.email
      });
    }
  }, [user, posthog]);

  useEffect(() => {
    // check if there is a ref query param
    const ref = router.query.ref;
    if (ref) {
      localStorage.setItem('referral', ref as string);
    }
  }, [router.query.ref]);

  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <link href="/favicon.ico" rel="shortcut icon" />
        <meta content={meta.description} name="description" />
        <meta
          property="og:url"
          content={`https://mendable.ai${router.asPath}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Mendable" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        {/* <meta property="og:image" content={meta.cardImage} /> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@vercel" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />

        <meta
          property="og:image"
          content={meta.ogImage}
        />
        {/* <meta name="twitter:image" content={meta.cardImage} /> */}
      </Head>
      {/* { dontIncludeNav(router.pathname) ? <div style={{backgroundColor: 'rgb(133 89 244/var(--tw-bg-opacity))', textAlign: 'center', color: 'white', padding: '5px',}}>
        We will be down for maintenance on Saturday from 9am-12pm ET (12/9/23)
  </div> : <></> } */}
       
      {!dontIncludeNav(router.pathname) ? <Navbar /> : <></>}
      <main id="skip">{children}</main>
      {!dontIncludeNav(router.pathname) && !router.pathname.includes("/launches/tools") ? <Footer /> : <></>}
    </>
  );
}
