'use client';

import * as React from 'react';
import Link from 'next/link';
import { RiCustomerService2Fill } from 'react-icons/ri';
import { cn } from '@/lib/utils';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle
} from '@/components/ui/navigation-menu';
import Logo from '@/components/icons/Logo';
import signInWithGoogle from '@/components/auth/signInWithGoogle';
import MagicWand from '../Icons/MagicWand';
import { Wand } from 'lucide-react';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BiSupport } from 'react-icons/bi';
import { AiOutlineRobot } from 'react-icons/ai';
import { FaChalkboardTeacher } from 'react-icons/fa';

const useCases: {
  title: string;
  href: string;
  description: string;
  icon?: any;
}[] = [
  {
    title: 'Sales Enablement',
    href: '/usecases/sales-enablement',
    description: 'Use a technical AI copilot to build trust with prospects',
    icon: (
      <FaChalkboardTeacher className="mr-2 min-w-4 group-hover:animate-rotate-hover  flex-shrink-0" />
    )
  },
  {
    title: 'Customer Success Enablement',
    href: '/usecases/cs-enablement',
    description: 'Use a technical AI copilot to increase retention',
    icon: (
      <RiCustomerService2Fill className="mr-2 min-w-4 group-hover:animate-rotate-hover  flex-shrink-0" />
    )
  },
  {
    title: 'Docs & Knowledge Base',
    href: '/usecases/documentation',
    description:
      'Decrease tickets and activation times with docs based answers',
    icon: (
      <IoDocumentTextOutline className="mr-2 min-w-4  group-hover:animate-rotate-hover transition-all flex-shrink-0" />
    )
  },

  // {
  //   title: 'Customer Support',
  //   href: '/usecases/customersupport',
  //   description:
  //       "Resolve half of all customer support tickets instantly",
  //   icon: <BiSupport className='mr-2 min-w-4 group-hover:animate-rotate-hover  flex-shrink-0' />
  // },
  {
    title: 'Product Copilot',
    href: '/usecases/productcopilot',
    description:
      'Speed up adoption times with a technical assistant in your app',
    icon: (
      <AiOutlineRobot className="mr-2 min-w-4 group-hover:animate-rotate-hover  flex-shrink-0" />
    )
  }
];


export default function NavigationBar() {
  const [menuItems, setMenuItems] = React.useState([
    { name: 'Documentation', link: '/usecases/documentation' },
    { name: 'Customer Support', link: '/usecases/customersupport' },
    { name: 'Product Copilot', link: '/usecases/productcopilot' },
    { name: 'Sales Enablement', link: '/usecases/salescopilot' }
  ]);
  return (
    <NavigationMenu className="">
      <NavigationMenuList>
        <NavigationMenuItem className="hidden lg:flex">
          <NavigationMenuTrigger>Getting started</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <a
                    className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-black to-gray-800 p-6 no-underline outline-none focus:shadow-md"
                    href="/"
                  >
                    <Logo />
                    <div className="mb-2 mt-4 text-lg font-medium">
                      Mendable.ai
                    </div>
                    <p className="text-sm leading-tight text-muted-foreground">
                      Create enterprise-ready 'chat with data' apps in minutes
                    </p>
                  </a>
                </NavigationMenuLink>
              </li>
              <ListItem href="/signup" title="Create an account">
                Start by creating an account and ingesting documents.
              </ListItem>
              <ListItem href="#usecases" title="Use Cases">
                Implement Mendable AI in your docs, support, sales, and product
              </ListItem>
              <ListItem
                href="https://docs.mendable.ai/components/search-bar"
                title="Embed in your website"
              >
                Easily embed chatbot or search bar in your website with React.
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Use Cases</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {useCases.map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                  icon={component.icon}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="https://docs.mendable.ai" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Docs
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/pricing" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Pricing
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/blog" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Blog
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'> & {
    icon?: React.ReactNode;
    title: string;
  }
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            'block select-none space-y-1 group rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-800 hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
            className
          )}
          {...props}
        >
          <div className="flex">
            {/* {icon && <Wand className='mr-2 min-w-4  flex-shrink-0' />} */}
            {props.icon}
            <div className="flex flex-col">
              <div className="text-sm font-semibold leading-none">{title}</div>
              <p className="line-clamp-2 text-sm leading-snug text-gray-300">
                {children}
              </p>
            </div>
          </div>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = 'ListItem';
