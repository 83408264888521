import React from 'react';
import { CgFileDocument } from 'react-icons/cg';
import PrettyWrapper from './ui/PrettyWrapper';
import { Button } from './ui/button';

export default function DocumentationCTA(
  {title = 'See docs for further customization',
  description = 'All this customizations changes are done on the component level. The documentation has more information on how to change other aspects of the components such as icons.',
  link = 'https://docs.mendable.ai'
}:{
  title?: string,
  description?: string,
  link?: string
}) {
  return (
    <PrettyWrapper className="mt-4">
      <h2>{title}</h2>
      <p className="mt-1 text-xs text-gray-400">
        {description}
      </p>
      <Button
        className="mt-2 text-sm h-8 bg-gray-800/40 border border-gray-800 px-2 py-[2px] hover:bg-gray-800"
        onClick={() => {
          // send email
          window.open(link);
        }}
      >
        <CgFileDocument className="mr-2" />
        Documentation
      </Button>
    </PrettyWrapper>
  );
}
