import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Button } from './ui/button';
import { MdEmail, MdHelp } from 'react-icons/md';
import { IoLogoDiscord } from 'react-icons/io5';

export default function HelpPopover({
    helpText = "Help"
}:{
    helpText?: string
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className="text-sm h-9 bg-gray-800/40 border border-gray-800 px-2 py-[2px] hover:bg-gray-800"
        >
          <MdHelp className="mr-2" />
          <p>{helpText}</p>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="mt-2 w-80 bg-gray-900 border-2 border-gray-800 ring-0 outline-none">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className=" leading-none font-bold">{helpText}</h4>
          </div>
          <div className="flex flex-row justify-between items-center gap-2">
            <Button
              onClick={() => {
                window.open('https://discord.gg/kJufGDb7AA');
              }}
              variant="outline"
              className=" text-sm h-8 bg-gray-700 border border-gray-800 px-2 py-[2px] hover:bg-opacity-75"
            >
              <IoLogoDiscord className="mr-2" />
              <p>Community</p>
            </Button>
            <Button
              onClick={() => {
                //mailto
                window.open('mailto:help@mendable.ai');
              }}
              variant="outline"
              className=" text-sm h-8 bg-gray-700 border border-gray-800 px-2 py-[2px] hover:bg-opacity-75"
            >
              <MdEmail className="mr-2" />
              <p>Contact support</p>
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
