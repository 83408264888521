import React, { ReactNode } from 'react';

interface PrettyWrapperProps {
    children: ReactNode;
    className?: string;
}

const PrettyWrapper: React.FC<PrettyWrapperProps> = ({ children, className }) => {
    return (
        <div className={`mb-4 sm:mb-8 space-y-4 sm:space-y-8 flex flex-col items-start h-full min-w-[300px] ${className}`}>
            <div className="w-full shrink-0 rounded-xl p-4 border border-gray-800 bg-gray-950/25 ">
                {/* <div className="absolute -top-px left-20 right-11 h-px bg-gradient-to-r from-primary/0 via-primary/70 to-primary/0" /> */}
                {/* <div className="absolute -bottom-px left-11 right-20 h-px bg-gradient-to-r from-blue-400/0 via-blue-400 to-blue-400/0" /> */}

                {children}
            </div>
        </div>
    );
};

export default PrettyWrapper;