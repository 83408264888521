import ReactMarkdown from 'react-markdown';
import Fence from '@/components/ui/Fence';
import { Message } from 'types';

// add props interface
interface MarkdownProps {
    str: string;
    remarkPlugins?: any;
}

export function Markdown({ str, remarkPlugins }: MarkdownProps) {
    return (
      <ReactMarkdown
      
      remarkPlugins={remarkPlugins}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            if (inline) {
              return (
                <code
                  className={`bg-opacity-50 overflow-auto p-1 m-1 rounded-md before:content-none after:content-none `}
                  {...props}
                >
                  {String(children)}
                </code>
              );
            }
  
            return (
              <code
              style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word' }}
                className="bg-transparent overflow-auto bg-opacity-25 rounded-md before:content-none after:content-none"
                {...props}
              >
                {
                  <Fence
                    language={match ? match[1] : ''}
                    children={String(children)}
                  />
                }
              </code>
            );
          },
        }}
        linkTarget={'_blank'}
        className={`prose !max-w-full overflow-auto prose-mx text-xs sm:text-base prose-p:text-white prose-strong:text-white prose-headings:text-white prose-a:text-white prose-li:text-white prose-blockquote:text-white prose-code:text-white`}
        //set the max width in the style 
      >
        {str}
      </ReactMarkdown>
    );
  }
  