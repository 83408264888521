// create a simple zustand store that will be used to refresh the app layout

import { create } from 'zustand';

type RefreshAppLayoutState = {
  isRefreshed: boolean;
  refreshAppLayout: () => void;
};

export const useRefreshAppLayoutStore = create<RefreshAppLayoutState>(
  (set) => ({
    isRefreshed: false,
    refreshAppLayout: () => {
      set((state) => ({ isRefreshed: !state.isRefreshed }));
    }
  })
);

// example on how to use it
// import { useRefreshAppLayoutStore } from '@/lib/zustand/refreshAppLayout';

// const refreshAppLayout = useRefreshAppLayoutStore((state) => state.refreshAppLayout);
// refreshAppLayout();
// or
// useRefreshAppLayoutStore.getState().refreshAppLayout();
// or
// useRefreshAppLayoutStore.getState().isRefreshed;



