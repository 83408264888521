// CookieBanner.js

import posthog from 'posthog-js';
import { useState } from 'react';
import PrettyWrapper from './PrettyWrapper';

interface CookieBannerProps {
    posthogClient: any
}

export function CookieBanner({posthogClient}: CookieBannerProps) {
    const [showBanner, setShowBanner] = useState(true);

    const acceptCookies = () => {
        posthog.opt_in_capturing();
        setShowBanner(false);
    };

    const declineCookies = () => {
        posthog.opt_out_capturing();
        setShowBanner(false);
    };

    return (
        <div>
            {posthogClient && showBanner && (
                <div className="fixed max-w-[70%] bottom-2 left-8 rounded-md shadow-md z-50">
                    <PrettyWrapper>
                        <p className="text-sm">
                            We use tracking cookies to understand how you use the product
                            and help us improve it! <br/> Please accept cookies to help us improve.
                        </p>
                        <div className="flex mt-2 space-x-2">
                            <button
                                type="button"
                                onClick={acceptCookies}
                                className="bg-primary text-white py-1 px-3 rounded"
                            >
                                Accept Cookies
                            </button>
                            <button
                                type="button"
                                onClick={declineCookies}
                                className=" text-white py-1 px-3 rounded hover:bg-gray-700"
                            >
                                Decline Cookies
                            </button>
                        </div>
                    </PrettyWrapper>
                </div>
            )}
        </div>
    );
}

export default CookieBanner;