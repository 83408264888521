import Highlight, { defaultProps } from 'prism-react-renderer';
import React, { Fragment, useState } from 'react';

import clsx from 'clsx';

const codeLanguage = 'jsx';
const defaultCode = `
import { MendableSearch } from '@mendable/search'

<MendableSearchBar anon_key={MENDABLE_ANON_KEY} />
`;

const tabs = [
  { name: 'Search.tsx', isActive: true }
  // { name: 'package.json', isActive: false },
];

function TrafficLightsIcon(props: any) {
  return (
    <svg aria-hidden="true" viewBox="0 0 42 10" fill="none" {...props}>
      <circle cx="5" cy="5" r="4.5" />
      <circle cx="21" cy="5" r="4.5" />
      <circle cx="37" cy="5" r="4.5" />
    </svg>
  );
}
export default function CodeDisplay({
  code = defaultCode,
  simple = false
}: {
  code?: string;
  simple?: boolean;
}) {
  return (
    <div className=" relative rounded-xl bg-gray-800/25 ring-1 ring-white/10 backdrop-blur">
      <div className="absolute -top-px left-20 right-11 h-px bg-gradient-to-r from-primary/0 via-primary/70 to-primary/0" />
      <div className="absolute -bottom-px left-11 right-20 h-px bg-gradient-to-r from-blue-400/0 via-blue-400 to-blue-400/0" />
      <div className="pl-2 pt-2">
        {!simple && (
          <TrafficLightsIcon className="h-2.5 w-auto stroke-slate-500/30" />
        )}
        {!simple && (
          <div className="mt-4 flex space-x-2 text-xs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                className={clsx(
                  'flex h-6 rounded-full',
                  tab.isActive
                    ? 'bg-gradient-to-r from-primary/30 via-primary to-primary/30 p-px font-medium text-purple-400'
                    : 'text-slate-500'
                )}
              >
                <div
                  className={clsx(
                    'flex items-center rounded-full px-2.5',
                    tab.isActive && 'bg-slate-800'
                  )}
                >
                  {tab.name}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="mt-6 flex items-start px-1 text-xs sm:text-sm">
          <div
            aria-hidden="true"
            className="select-none border-r border-slate-300/5 pr-2 font-mono text-slate-600"
          >
            {Array.from({
              length: code.split('\n').length
            }).map((_, index) => (
              <Fragment key={index}>
                {(index + 1).toString().padStart(2, '0')}
                <br />
              </Fragment>
            ))}
          </div>
          <Highlight
            {...defaultProps}
            code={code}
            language={codeLanguage}
            theme={undefined}
          >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre
                className={clsx(className, 'flex overflow-x-auto pb-6')}
                style={style}
              >
                <code className="px-4">
                  {tokens.map((line, lineIndex) => (
                    <div key={lineIndex} {...getLineProps({ line })}>
                      {line.map((token, tokenIndex) => (
                        <span key={tokenIndex} {...getTokenProps({ token })} />
                      ))}
                    </div>
                  ))}
                </code>
              </pre>
            )}
          </Highlight>
        </div>
      </div>
    </div>
  );
}
export function CodeDisplaySimple({
  code = defaultCode,
  small = false,
  pulse = false,
}: {
  code?: string;
  small?: boolean;
  pulse?: boolean;
}) {
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div style={{
    }} className={`${!small ? "" : ""} ${pulse ? "ring-green-500" : "ring-white/10"} transition-all relative rounded-xl ring-1 bg-gray-800/25  backdrop-blur`}>
      {!small && <div className="absolute -top-px left-20 right-11 h-px bg-gradient-to-r from-primary/0 via-primary/70 to-primary/0" /> }
      {!small && <div className="absolute -bottom-px left-11 right-20 h-px bg-gradient-to-r from-blue-400/0 via-blue-400 to-blue-400/0" /> }
      <div className="pl-4 pt-4">
        <TrafficLightsIcon className="h-2.5 w-auto stroke-slate-500/30" />
        <div
          className={`mt-2 flex items-start justify-between px-1 text-xs ${
            small ? '' : 'sm:text-sm'
          }`}
        >
          <div className="flex-1 overflow-auto">
            <Highlight
              {...defaultProps}
              code={code}
              language={codeLanguage}
              theme={undefined}
            >
              {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <pre
                  className={clsx(className, 'flex overflow-x-auto pb-2')}
                  style={style}
                >
                  <code className="">
                    {tokens.map((line, lineIndex) => (
                      <div key={lineIndex} {...getLineProps({ line })}>
                        {line.map((token, tokenIndex) => (
                          <span
                            key={tokenIndex}
                            {...getTokenProps({ token })}
                          />
                        ))}
                      </div>
                    ))}
                  </code>
                </pre>
              )}
            </Highlight>
          </div>
          <button
            className="ml-2 w-6 h-6 -mt-4 rounded-md p-1 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700"
            onClick={handleCopyClick}
          >
            {copied ? (
              <svg
                className="h-4 w-4 stroke-current text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            ) : (
              <svg
                className="h-4 w-4 stroke-current text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
