import Stripe from 'stripe';
import { Conversation } from './utils/types';

export interface MendableProject {
  id: number;
  name: string;
  //company_id foreign key
  company_id: number;
  support_url: string;
  pricing_plan_id: number;
  trial_end?: Date | null;
  display_id: string;
  prompt_id: number;
  isFaqPublic: boolean;
  max_messages_per_month: number;
  enforce_whitelist?: boolean;
  max_req_per_ip_per_minute?: number;
  whitelisted_domains?: string[];
  server_auth_key?: string;
  is_router?: boolean;
  is_favorite?: boolean;
  location?: string;
  is_tools_enabled: boolean;
  retriever_enabled: boolean;
  is_test_mode: boolean;
  production_project_id?: number;
}

export interface Role {
  role_id: number;
  role_name: string;
}

export interface Company {
  company_id: number;
  name: string;
  pricing_plan_id: number;
  location?: string;
}

export interface PageMeta {
  title: string;
  description: string;
  cardImage: string;
}

export interface Customer {
  id: string /* primary key */;
  stripe_customer_id?: string;
}

export interface Product {
  id: string /* primary key */;
  active?: boolean;
  name?: string;
  description?: string;
  image?: string;
  metadata?: Stripe.Metadata;
}

export interface ProductWithPrice extends Product {
  prices?: Price[];
}

export interface UserDetails {
  id: string /* primary key */;
  first_name: string;
  last_name: string;
  full_name?: string;
  avatar_url?: string;
  company_id?: number;
  email: string;
  billing_address?: Stripe.Address;
  payment_method?: Stripe.PaymentMethod[Stripe.PaymentMethod.Type];
}

export interface Price {
  id: string /* primary key */;
  product_id?: string /* foreign key to products.id */;
  active?: boolean;
  description?: string;
  unit_amount?: number;
  currency?: string;
  type?: Stripe.Price.Type;
  interval?: Stripe.Price.Recurring.Interval;
  interval_count?: number;
  trial_period_days?: number | null;
  metadata?: Stripe.Metadata;
  products?: Product;
}

export interface PriceWithProduct extends Price {}

export interface Subscription {
  id: string /* primary key */;
  user_id: string;
  status?: Stripe.Subscription.Status;
  metadata?: Stripe.Metadata;
  price_id?: string /* foreign key to prices.id */;
  quantity?: number;
  cancel_at_period_end?: boolean;
  created: string;
  current_period_start: string;
  current_period_end: string;
  ended_at?: string;
  cancel_at?: string;
  canceled_at?: string;
  trial_start?: string;
  trial_end?: string;
  prices?: Price;
  mendable_token: string;
}

export interface Message {
  conversation_id: number;
  message: string;
  message_id: number;
  rating_value: number;
  sender: string;
  timestamp: string;
  sources?: any;
  prev_message: string;
  prev_message_id?: string;
  prev_timestamp: string;
  prev_sender: string;
}

export interface ConversationStatus {
  status_id: number;
  conversation_id: number;
  project_id: number;
  is_read: boolean;
  is_bookmarked: boolean;
}

export interface ApiKey {
  id: number;
  created_at: string;
  key: string;
  name: string;
  project_id: number;
  keyType: string;
}

export interface CompleteConversation {
  page: number;
  conversation: Conversation;
  conversation_id: number | string;
  messages: Message[];
  messagePairs: Message[][];
}

export interface AnsweredQuestion {
  question_id: number;
  content: string;
}

export interface SourceForDatapoint {
  content: string;
  link: string;
  datapoint_id: number;
}

export interface Source {
  id: number;
  content: string;
  link: string;
  message_id: number;
}
export enum Filter {
  ALL = 'all',
  UNREAD = 'unread',
  TAUGHT = 'taught',
  BOOKMARKS = 'saved'
}

export enum DateFilter {
  TODAY = 'today',
  LAST7DAYS = 'last7days',
  LAST30DAYS = 'last30days',
  LAST60DAYS = 'last60days',
  LAST90DAYS = 'last90days',
  LAST180DAYS = 'last180days',
  ALLTIME = 'alltime'
}

export enum FeedbackFilter {
  THUMBSUP = 'thumbsup',
  THUMBSDOWN = 'thumbsdown',
  ALL = 'all'
}

export enum OnboardingSteps {
  createOrg = 'createOrg',
  addData = 'addData',
  askQuestions = 'askQuestions',
  viewConversation = 'viewConversation',
  embedWidget = 'embedWidget'
}

// RUNNING, PAUSED, STOPPED, SUCCESS, ERROR
export enum SyncStatus {
  RUNNING = 'RUNNING',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export interface DataIngestionJob {
  is_initial?: boolean;
  id?: number;
  internal_job_id?: string;
  url: string;
  company?: string;
  company_id?: number;
  project_id: number;
  ingestion_connector_id?: number;
  ingestion_type: string; // same as data_id
  created_at?: Date;
  updated_at?: Date;
  last_run_status?: string;
  status?: string; // 'pending' | 'processing' | 'completed' | 'failed'
  data_status?: string; // ; 'crawling' | 'splitting' | 'embedding' | 'saving'
  document_ids?: number[]; // or string[] if your IDs are strings
  schedule?: string; // if empty, `auto_reingestion` is false
  exclude_sources?: string[]; // if empty, all sources are included
  next_run?: Date;
  // records?: Record[]; //if documents are already ready, no need to crawl , not part of the
  optional_data?: any; // json
}

export interface RouterProject {
  id: number;
  router_project_id: number;
  sub_project_id: number;
  criteria: string;
  is_default: boolean;
  example_queries: string;
}

export interface Tool {
  id: string; // UUID
  tool_name: string;
  tool_type: string;
  company: string;
  criteria: string;
  response_prompt: string;
  full_request: string;
  auth_key: string;
  metadata: Record<string, any>; // JSONB represented as an object
  oauth_id: string | null;
  oauth_action: string | null;
  oauth_sync: string | null;
  oauth_params: string | null;
  is_oauth: boolean;
  project_id: number | null; // Replace with the correct type if it's not a number
  template_id: string; // UUID
  api_request_id: string; // UUID
  is_enabled: boolean;
  is_action: boolean;
}

// -- CREATE TABLE templates (
//   --     id UUID PRIMARY KEY,
//   --     template_name TEXT,
//   --     description TEXT,
//   --     image TEXT,
//   --     -- necessary_fiedlds JSONB,// { name: string }
//   --     request_template TEXT,
//   --     necessary_fields JSONB,
//   --     created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
//   --     updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
//   -- );

export interface TemplateField {
  name: string;
  type: string;
  required: boolean;
  default_value?: string;
}
export interface Template {
  id: string; // UUID
  template_name: string;

  criteria: string;
  response_prompt: string;

  description: string;
  image: string;

  request_url_field: string | null;
  query_fields: TemplateField[] | null; // JSONB represented as an object
  header_fields: TemplateField[] | null; // JSONB represented as an object
  body_fields: TemplateField[] | null; // JSONB represented as an object

  tags: any | null; // JSONB represented as an object
  created_at: Date;
  updated_at: Date;
  api_request_id: string; // UUID
  enabled_cids?: number[];
  is_action: boolean;


  nango_provider: string;
  instructions?: string;
}

export interface TemplateTag {
  name: string;
  is_action: boolean;
}
// -- CREATE TABLE api_requests (
//   --     id UUID PRIMARY KEY,
//   --     -- request_name TEXT,
//   --     -- description TEXT,
//   --     api_endpoint TEXT,
//   --     http_method TEXT,
//   --     header_params JSONB,
//   --     body_params JSONB, -- { key: string, value: string }
//   --     -- response_fields JSONB, -- { field_name: string, field_type: string }
//   --     created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
//   --     updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
//   -- );
export interface ApiRequest {
  id: string; // UUID
  api_endpoint: string;
  http_method: string;
  query_params: Record<string, any>; // JSONB represented as an object
  header_params: Record<string, any>; // JSONB represented as an object
  body_params: Record<string, any>; // JSONB represented as an object
  created_at?: Date;
  updated_at?: Date;

  // not in the db, local use
  is_editable?: boolean;
  omit_secret?: boolean;
}

export interface ContextNotifications {
  id: number;
  project_id: number;
  job_id: string;
  status: string;
  created_at: string;
  updated_at: string;
  ingestion_type: string;
  url: string;
  display: boolean;
}

export interface TaskStatus {
  current_step: string;
  current: number;
  current_url?: string;
  total: number;
  taskStatus: string;
  status: string;
  data?: {
    success: boolean;
    error: string;
    errorMessage: string;
  }
}

export interface FinalContextNotification extends ContextNotifications, TaskStatus {
  context_status: string;
  task_status: string;
}

export interface OAuthConnection {
  id: number;
  nango_connection_id: string;
  user_id: string;
  provider: string;
  refresh_token?: string;
  project_id: number;
}

export interface ConnectionProvider {
  id: number;
  provider: string;
  icon: string;
  name: string;
}


export interface SlackConnection {
  id: number;
  user_id: string;
  channel_id: string;
}

