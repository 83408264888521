import Logo from '@/components/icons/Logo';
import React from 'react';

export default function Footer() {
  return (
    <footer className="mx-auto max-w-5xl px-6 pt-36 pb-12 md:max-w-7xl">
      <div className="flex w-full flex-col gap-8 sm:flex-row pb-10">
        <div className="flex w-1/3 flex-col">
          <div className="w-full flex items-center">
            <Logo className="flex-shrink-0 w-8 h-8 rounded-xl mr-3 text-white fill-current" />
            <a
              href="#_"
              className="text-lg  font-bold leading-none text-gray-50 select-none logo"
            >
              Mendable
            </a>
          </div>
          <div className="mt-6 flex w-full gap-4">
            <a
              href="https://instagram.com/sideguide.dev"
              className="text-gray-400 hover:text-gray-50"
            >
              <span className="sr-only">Instagram</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>

            <a
              href="https://twitter.com/mendableai"
              className="text-gray-400 hover:text-gray-50"
            >
              <span className="sr-only">Twitter</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
              </svg>
            </a>

            <a
              href="https://github.com/sideguide"
              className="text-gray-400 hover:text-gray-50"
            >
              <span className="sr-only">GitHub</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://discord.com/invite/kJufGDb7AA"
              className="text-gray-400 hover:text-gray-50"
            >
              <span className="sr-only">Discord</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 127.14 96.36"
              >
                <g id="图层_2" data-name="图层 2">
                  <g id="Discord_Logos" data-name="Discord Logos">
                    <g
                      id="Discord_Logo_-_Large_-_White"
                      data-name="Discord Logo - Large - White"
                    >
                      <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div className="mt-6 flex w-full gap-4">
            <img
              src="/soc2type2badge.png"
              alt="SOC 2 Type II"
              className=" h-20  object-cover"
            />
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-8 sm:w-2/3 lg:grid-cols-4">
          <div className="flex flex-col gap-4">
            <p className="mb-2 ml-1.5 text-sm ">
              Documentation
            </p>
            <ul>
              <li>
                <a
                  className="outline-none text-gray-300 text-gray-300 transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/signup"
                >
                  Getting Started
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="https://docs.mendable.ai"
                  target='_blank' rel="noopener noreferrer"

                >
                  API Docs
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="https://docs.mendable.ai/integrations/slack"
                  target='_blank' rel="noopener noreferrer"

                >
                  Integrations
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="https://docs.mendable.ai/examples"
                  target='_blank' rel="noopener noreferrer"

                >
                  Examples
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="https://docs.mendable.ai/tools"
                  target='_blank' rel="noopener noreferrer"

                >
                  {"Tools & Actions"}
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <p className="mb-2 ml-1.5 text-sm  font-normal">
              Use Cases
            </p>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/usecases/sales-enablement"
                >
                  Sales Enablement
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/usecases/documentation"
                >
                  {"Knowledge Base"}
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/usecases/cs-enablement"
                >
                  CS Enablement
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/usecases/productcopilot"
                >
                  Product Copilot
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <p className="mb-2 ml-1.5 text-sm font-normal">
              Resources
            </p>
            
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/pricing"
                >
                  Pricing
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="https://docs.mendable.ai/changelog"
                  target='_blank' rel="noopener noreferrer"
                >
                  Changelog
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/security"
                >
                  Security
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="https://mendable.wolfia.com/?ref=mendable-footer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AI Trust Center
                </a>
              </li>
            </ul>
            
          </div>
          <div className="flex flex-col gap-4">
            <p className="mb-2 ml-1.5 text-sm font-normal">
              Company
            </p>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/blog"
                >
                  Blog
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="mailto:eric@mendable.ai"
                >
                  Contact
                </a>
              </li>
            </ul>
            {/* <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/customers"
                >
                  Customers
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="transition duration-150 ease-in-out focus-visible:ring-2 focus-visible:ring-slate-7 rounded-md px-1 py-0.5 text-sm text-slate-11 outline-none text-gray-300 hover:text-gray-400 focus-visible:text-gray-400"
                  href="/brand"
                >
                  Brand
                </a>
              </li>
            </ul> */}
          </div>
          
        </div>
      </div>
      <div className="flex items-center mx-auto max-w-5xl  md:max-w-7xl">
        <p className="mt-4 text-sm text-gray-50  sm:mt-0">
          © 2024 SideGuide - SideGuide Technologies Inc.
        </p>
        <span className="inline-flex items-center justify-center mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-start ">
          <div className="hidden lg:flex w-min flex-row items-center gap-2 rounded-lg border border-gray-800 px-2 py-1 transition hover:bg-gray-900">
            {/* <div className="h-2 w-2 rounded-full bg-green-400"></div> */}
            <a
              className="whitespace-nowrap text-sm font-normal text-gray-400 "
              href="https://mendable.betteruptime.com"
              target="_blank"
              rel="noreferrer"
            >
              System Status
            </a>
          </div>
          <a
            className="flex lg:hidden whitespace-nowrap text-sm font-normal text-gray-400 "
            href="https://mendable.betteruptime.com"
            target="_blank"
            rel="noreferrer"
          >
            Status
          </a>
          <a
            className="hidden lg:flex whitespace-nowrap text-sm font-normal text-gray-400 "
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className="flex lg:hidden swhitespace-nowrap text-sm font-normal text-gray-400 "
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
          <a
            className="whitespace-nowrap text-sm font-normal text-gray-400 "
            href="/terms-of-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
        </span>
      </div>
    </footer>
  );

  return (
    <footer className="text-gray-700 bg-black body-font tails-selected-element mt-20">
      <div className="container flex flex-col items-center px-8 py-8 mx-auto max-w-7xl sm:flex-row">
        <Logo className="mb-4 sm:mb-0 flex-shrink-0 w-8 h-8 rounded-xl mr-3 text-white fill-current" />
        <a
          href="#_"
          className="text-lg  font-bold leading-none text-gray-50 select-none logo"
        >
          Mendable
        </a>
        <p className="mt-4 text-sm text-gray-50 sm:ml-4 sm:pl-4 sm:border-l sm:border-gray-200 sm:mt-0">
          © 2023 SideGuide - SideGuide Technologies Inc.
        </p>
        <span className="inline-flex items-center justify-center mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-start sm:mr-20">
          <div className="hidden lg:flex w-min flex-row items-center gap-2 rounded-lg border border-gray-800 px-2 py-1 transition hover:bg-gray-900">
            {/* <div className="h-2 w-2 rounded-full bg-green-400"></div> */}
            <a
              className="whitespace-nowrap text-sm font-normal text-gray-400 "
              href="https://mendable.betteruptime.com"
              target="_blank"
              rel="noreferrer"
            >
              System Status
            </a>
          </div>
          <a
            className="flex lg:hidden whitespace-nowrap text-sm font-normal text-gray-400 "
            href="https://mendable.betteruptime.com"
            target="_blank"
            rel="noreferrer"
          >
            Status
          </a>
          <a
            className="hidden lg:flex whitespace-nowrap text-sm font-normal text-gray-400 "
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className="flex lg:hidden swhitespace-nowrap text-sm font-normal text-gray-400 "
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
          <a
            className="whitespace-nowrap text-sm font-normal text-gray-400 "
            href="/terms-of-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>

          <a
            href="https://instagram.com/sideguide.dev"
            className="text-gray-400 hover:text-gray-50"
          >
            <span className="sr-only">Instagram</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>

          <a
            href="https://twitter.com/mendableai"
            className="text-gray-400 hover:text-gray-50"
          >
            <span className="sr-only">Twitter</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
            </svg>
          </a>

          <a
            href="https://github.com/sideguide"
            className="text-gray-400 hover:text-gray-50"
          >
            <span className="sr-only">GitHub</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <a
            href="https://discord.com/invite/kJufGDb7AA"
            className="text-gray-400 hover:text-gray-50"
          >
            <span className="sr-only">Discord</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 127.14 96.36"
            >
              <g id="图层_2" data-name="图层 2">
                <g id="Discord_Logos" data-name="Discord Logos">
                  <g
                    id="Discord_Logo_-_Large_-_White"
                    data-name="Discord Logo - Large - White"
                  >
                    <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" />
                  </g>
                </g>
              </g>
            </svg>
          </a>
        </span>
      </div>
      <div className="container flex flex-col items-center px-8  mx-auto max-w-7xl sm:flex-row ">
        <img
          src="/soc2type2badge.png"
          alt="SOC 2 Type II"
          className=" h-24  object-cover"
        />
      </div>
    </footer>
  );
}
