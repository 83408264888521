export const ShimmerCard = () => {
    return (
        <div className="w-full bg-gray-700/50 border border-gray-800 p-4 rounded-md mb-4">
            <div className="h-4 rounded-lg bg-gray-700/50 animate-pulse mb-2 w-3/4"></div>
            <div className="h-4 rounded-lg bg-gray-700/50 animate-pulse mb-2 w-1/2"></div>
            <div className="h-4 rounded-lg bg-gray-700/50 animate-pulse mb-2 w-full"></div>
            <div className="h-4 rounded-lg bg-gray-700/50 animate-pulse mb-2 w-1/2"></div>
        </div>
    );
};
export const Shimmer= () => {
    return (
        <div className="w-full p-2">
            <div className="h-4 rounded-lg bg-gray-600 animate-pulse mb-2 w-3/4"></div>
            <div className="h-4 rounded-lg bg-gray-700 animate-pulse mb-2 w-1/2"></div>
            <div className="h-4 rounded-lg bg-gray-800 animate-pulse mb-2 w-full"></div>
            <div className="h-4 rounded-lg bg-gray-600 animate-pulse mb-2 w-1/2"></div>
        </div>
    );
};
import Link from 'next/link';
import React from 'react';
import { posthog } from 'posthog-js';
import { CompleteConversation } from '@/types';
import { formatHumanReadable } from '@/lib/app/conversations/utils';
import { ThumbsDown, ThumbsUp } from 'lucide-react';

interface Conversation {
  conversation_id: number;
  start_time: string;
  // other conversation properties
}

interface ConversationCardProps {
  text: string;
  wasRead: boolean;
  selected: boolean;
  conversation: CompleteConversation;
  rating: number;
  company_id?: number;
  project_id?: number;
  isLoading?: boolean; // Add isLoading prop
  onClick: (e: any) => any;
}

// export default function ConversationCard({
//   text = 'How do I get started?',
//   wasRead = false,
//   selected = false,
//   conversation,
//   rating = 0,
//   company_id,
//   project_id,
//   onClick,
//   isLoading // Destructure isLoading prop
// }: ConversationCardProps) {
//   return (
//     <div
//       onClick={(e) => {
//         posthog.capture('conversation_card_clicked', {
//           project_id: project_id
//         });
//         onClick(e);
//       }}
//     >
//       {/* /app/conversation/${company_id}/${conversation.conversation_id}?project_id=${project_id} */}
//       <div
//         className={` mb-2 relative  w-full ${
//           !selected && wasRead
//             ? 'bg-gray-700/50 border-gray-700 '
//             : 'bg-gray-700/50 border-primary  '
//         }
//           ${
//             selected ? 'bg-gray-700/70 border-2  border-primary ' : 'border-l-2'
//           } 
//           transition duration-200 cursor-pointer hover:bg-gray-700/60  rounded-md  shadow-md hover:shadow-lg`}
//       >
//         <div className="flex py-[1px] ">
//           {/* <div
//             className={`${
//              !selected && wasRead ? 'bg-gray-500' : 'bg-primary'} ${selected && !wasRead ? 'bg-gray-500' : ''}
//             } rounded-full ml-[0.5px]  w-[2px] min-h-16  `}
//           /> */}

//           {/* <p className="text-sm text-gray-500">First Question:</p> */}
//           <div className="p-3 flex flex-col w-full">
//             <p
//               className={`${
//                 wasRead ? 'font-light' : 'font-semibold'
//               }  max-w-[90%] text-sm text-[#fafafa] overflow-hidden whitespace-nowrap overflow-ellipsis`}
//             >
//               {text}
//             </p>
//             <p className="font-light pt-1 text-[12px] text-gray-300">
//               {formatHumanReadable(conversation.conversation.start_time ?? '')}
//             </p>
//           </div>
//         </div>

//         {/* display conversation length */}
//         <div className="pr-2 absolute top-1 right-2 flex items-center justify-between mt-2">
//           <div
//             className={`text-xs w-5 h-5 flex items-center justify-center rounded-full ${
//               !wasRead ? 'bg-primary' : 'bg-gray-700'
//             }`}
//           >
//             {conversation.messages.length}
//           </div>
//         </div>
//         <div className="pr-2 absolute bottom-1 right-2 flex items-center justify-between mt-2">
//           <div
//             className={`text-xs flex items-center gap-2 justify-center rounded-full`}
//           >
//             {rating !== 0 ? 'Feedback: ' : null}
//             {rating === 0 ? null : rating > 0 ? (
//               <ThumbsUp size={14} className="fill-green-400 stroke-green-300" />
//             ) : (
//               <ThumbsDown size={14} className="fill-red-400 stroke-red-300" />
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


// conversation shimmer based on the actual conversation card up top
// create a loading version of it...
export function ConversationShimmerCard() {
  return (
    <div className="w-full bg-gray-700/50 border border-gray-800 p-2 rounded-md mb-2">
      <div className="h-2 rounded-lg bg-gray-700/50 animate-pulse mb-2 w-3/4"></div>
      <div className="h-2 rounded-lg bg-gray-700/50 animate-pulse mb-2 w-1/2"></div>
      <div className="h-2 rounded-lg bg-gray-700/50 animate-pulse mb-2 w-full"></div>
      <div className="h-2 rounded-lg bg-gray-700/50 animate-pulse w-1/2"></div>
    </div>
  );
}
