import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from './ui/dialog';
import { Button } from './ui/button';

export default function UpgradeDialog({
  setOpen,
  open,
  customText = 'Insights, Auto Syncs, Teach Model are only available for paid plans. Please contact us to upgrade.',
  onClick,
  useCalendly = false
}: {
  setOpen: any;
  open: boolean;
  customText?: string;
  onClick?: any;
  useCalendly?: boolean;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upgrade your project</DialogTitle>
          <DialogDescription>
            {customText}
            <div className="mt-5 flex items-center justify-end gap-4">
              <Button
                className="bg-primary hover:bg-opacity-50"
                onClick={() => {
                  if (useCalendly) {
                    // open in a new tab
                    window.open(
                      'mailto:help@firecrawl.com',
                      '_blank'
                    );
                  } else {
                    window.location.href = 'https://mendable.ai/pricing';
                  }
                }}
              >
                {useCalendly ? 'Talk to us' : 'Upgrade'}
              </Button>
              <Button
                className="bg-gray-700 hover:bg-opacity-50"
                onClick={(e) => {
                  setOpen(false);
                  e.stopPropagation();
                }}
              >
                Cancel
              </Button>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export function UpgradeTrialDialog({
  setOpen,
  open,
  onClick
}: {
  setOpen: any;
  open: boolean;
  onClick?: any;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Your trial has run out!</DialogTitle>
          <DialogDescription>
            Don't worry your messages are still being served. To reactivate your
            dashboard, let's chat!
            <div className="mt-5 flex items-center justify-end gap-4">
              <Button
                className="bg-primary hover:bg-opacity-50"
                onClick={() => {
                  window.location.href =
                    'mailto:help@firecrawl.com';
                }}
              >
                Talk to us!
              </Button>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
