import 'styles/main.css';
import 'styles/chrome-bug.css';
import "@mintlify/mdx/dist/styles.css";

import { useEffect, useState } from 'react';
import React from 'react';

import Layout from 'components/Layout';
import { SessionContextProvider, useUser } from '@supabase/auth-helpers-react';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { AppProps } from 'next/app';
import { MyUserContextProvider } from 'utils/useUser';
import type { Database } from 'types_db';
import { Analytics } from '@vercel/analytics/react';

import { Router, useRouter } from 'next/router';
import AppLayout from '@/components/app/AppLayout';
import { Grid } from 'react-loader-spinner';
import { Shimmer, ShimmerCard } from '@/components/ui/LoadingDots/ShimmerCard';
import DashboardLoading from '@/components/app/DashboardLoading';
import ProjectsDashboardLoading from '@/components/app/ProjectsDashboardLoading';
import { Toaster } from '@/components/app/ui/toaster';
import { RecoilRoot } from 'recoil';
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'
import { CookieBanner } from '@/components/app/ui/CookieBanner';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Toaster as SonnerToaster } from "@/components/ui/sonner"
const queryClient = new QueryClient();


  export default function MyApp({ Component, pageProps }: AppProps) {


    const [posthogClient, setPosthogClient] = useState<any>(null);
    useEffect(() => {
      if (typeof window !== 'undefined') {
        posthog.init("phc_5QcmSeCPZpv9hTm0DY3dQSR3aCNX2KV12icB6jFyVNg"!, {
          api_host: 'https://app.posthog.com',
          session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
              password: true,
            }
          },
          // Enable debug mode in development
          loaded: (posthog) => {
            // posthog.capture('test event');
            posthog.debug(false)
    
            if (process.env.NODE_ENV === 'development') {
              // posthog.debug()
            } else {
              posthog.debug(false)
            }
            setPosthogClient(posthog);
          }
        })
      }
    }, [])





    const [supabaseClient] = useState(() =>
      createBrowserSupabaseClient<Database>()
    );
    useEffect(() => {
      document.body.classList?.remove('loading');
    }, []);




    const router = useRouter();
    function dontIncludeNav(url: string) {
      const dontInclude = ['/karate-docs', '/search/embed/activepieces', '/search/embed/test', '/search/embed/activepieces2']
      console.log(router.pathname)
      return dontInclude.includes(url)
    }
    const [loading, setLoading] = React.useState(false);
    const [dashboardNav, setDashBoardNav] = React.useState('');
    React.useEffect(() => {
      const start = (url: string) => {
        posthog.capture('$pageview');
        if (url.startsWith('/app/')) {
          // grab last part of the url like /app/dashboard, get dashboard
          const nav = url.split('/').pop();
          setDashBoardNav(nav ?? '');
          setLoading(true);
        }else if (!url.includes('/app')) {
          const script = document.createElement("script");
          script.async = true;
          script.src = "https://cdn.getkoala.com/v1/pk_1a6990f4a07f15556939e3171131c3810711/sdk.js";
          document.head.appendChild(script);
      }
      };
      const end = () => {
        setLoading(false);
      };

      Router.events.on('routeChangeStart', start);
      Router.events.on('routeChangeComplete', end);
      Router.events.on('routeChangeError', end);
      return () => {
        Router.events.off('routeChangeStart', start);
        Router.events.off('routeChangeComplete', end);
        Router.events.off('routeChangeError', end);
      };
    }, []);



    // this is stupid but works for now, think of better solution later
    function isNotDashboarNavs(path: string) {
      const s = ['new_project', 'total-usage', 'account', 'team', 'connections'];
      return !s.includes(path);
    }

    return (
      <QueryClientProvider client={queryClient}>
        <div
          className={`${!dontIncludeNav(router.pathname) ? 'bg-black' : 'bg-transparent' 
            } dark`}
        >
          <PostHogProvider client={posthogClient}>
            <SessionContextProvider supabaseClient={supabaseClient}>
              <MyUserContextProvider>
                <RecoilRoot >
                  {/* @ts-ignore */}
                  <Layout meta={pageProps.pageMeta}>
                    {loading ? isNotDashboarNavs(dashboardNav) ? (
                      <DashboardLoading route={dashboardNav} />
                    ) :
                      <ProjectsDashboardLoading route={dashboardNav} />
                      : (
                        <Component {...pageProps} />
                      )}
                    <Analytics />
                    <Toaster />
                    <SonnerToaster />
                    {/* <CookieBanner></CookieBanner> */}
                    {posthog.has_opted_out_capturing() // new
                      || posthog.has_opted_in_capturing()
                      ? null
                      : <CookieBanner posthogClient={posthogClient}/>
                    }
                  </Layout>
                </RecoilRoot>
              </MyUserContextProvider>
            </SessionContextProvider>
          </PostHogProvider>
        </div>
      </QueryClientProvider>
    );
  }
