import React from 'react';
import { Shimmer } from '../ui/LoadingDots/ShimmerCard';
import AppLayout from './AppLayout';
import { useRecoilState } from 'recoil';
import { projectIdAtom } from '../atoms/applayout';

export default function DashboardLoading({ route }: { route: string }) {


  return (
    <AppLayout selected={route} isLoadingState={true}>
      <div className="mx-auto px-8 sm:px-12 lg:px-16">
        <div className="flex min-h-screen h-full">
          <div className="w-full">
            {/* <h2 className="text-lg mb-4 font-semibold">Conversations</h2> */}
            <Shimmer />
            <Shimmer />
            <Shimmer />
          </div>
        </div>
      </div>{' '}
    </AppLayout>
  );
}
