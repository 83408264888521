import React from 'react';

export default function Banner({text = "We will be down for maintenance on Saturday from 9am-12pm ET (12/9/23)"}:{text:string}) {
  return (
    <div
      className="w-full top-0"
      style={{
        backgroundColor: 'rgb(133 89 244/var(--tw-bg-opacity))',
        textAlign: 'center',
        color: 'white',
        padding: '5px'
      }}
    >
      {text}
    </div>
  );
}

export function TestModeBanner({text = "We will be down for maintenance on Saturday from 9am-12pm ET (12/9/23)"}:{text:string}) {
  return (
    <div
      className="w-full top-0 text-xs"
      style={{
        backgroundColor: '#f97316',
        textAlign: 'center',
        color: 'white',
        padding: '5px'
      }}
    >
      {text}
    </div>
  );
}
