import { create } from 'zustand';

export const useBearStore = create((set) => ({
  bears: 0,
  increasePopulation: () => set((state: any) => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 })
}));


// create
// store.ts

// type IngestionState = {
//   taskId: string | null;
//   progress: number;
//   status: 'idle' | 'processing' | 'completed' | 'error';
//   startIngestion: () => Promise<void>;
//   checkIngestionStatus: () => Promise<void>;
// }

// export const useIngestionStore = create<IngestionState>((set, get) => ({
//   // Initial state
//   taskId: null,
//   progress: 0,
//   status: 'idle',

//   // Action to initiate the ingestion process
//   startIngestion: async () => {
//     try {
//       // Simulate starting the ingestion process and getting a taskId
//       // This should be replaced with your actual API call to start the process
//       const startResponse = await fetch('/api/startIngestion');
//       if (!startResponse.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const { taskId } = await startResponse.json();

//       // Update the state with the taskId and start processing
//       set({ taskId, status: 'processing', progress: 0 });

//       // Immediately check the ingestion status after starting
//       get().checkIngestionStatus();
//     } catch (error) {
//       console.error("Failed to start ingestion process", error);
//       set({ status: 'error' });
//     }
//   },

//   // Action to check the ingestion status using the taskId
//   checkIngestionStatus: async () => {
//     const { taskId } = get();
//     if (!taskId) {
//       console.error("No taskId found for checking ingestion status");
//       return;
//     }

//     try {
//       // Replace with your actual API call, using taskId to check status
//       const statusResponse = await fetch(`/api/checkStatus/${taskId}`);
//       if (!statusResponse.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const { progress, status } = await statusResponse.json();

//       // Update the state based on the response
//       set({
//         progress,
//         status: status === 'completed' ? 'completed' : 'processing',
//       });

//       // If the process is still ongoing, schedule the next status check
//       if (status !== 'completed') {
//         setTimeout(() => get().checkIngestionStatus(), 5000); // Check every 5 seconds
//       }
//     } catch (error) {
//       console.error("Failed to check ingestion status", error);
//       set({ status: 'error' });
//     }
//   },
// }));
