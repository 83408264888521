import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Button } from './ui/button';
import { MdCode, MdEmail, MdHelp } from 'react-icons/md';
import { IoLogoDiscord } from 'react-icons/io5';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import CodeDisplay, { CodeDisplaySimple } from '../ui/CodeDisplay';
import DocumentationCTA from './DocumentationCTA';

export default function EmbedPopover({
  helpText = 'Help',
  anon_key = 'YOUR_ANON_KEY'
}: {
  helpText?: string;
  anon_key?: string;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className="text-sm h-9 bg-gray-800/40 border border-gray-800 px-2 py-[2px] hover:bg-gray-800"
        >
          <MdCode className="mr-2" />
          <p>Embed</p>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="mt-2 w-96 bg-gray-900 border-2 border-gray-800 ring-0 outline-none">
        <Tabs defaultValue="react" className="w-full mt-2">
          <div className="space-y-2">
            <h4 className=" leading-none font-bold">Embed</h4>
          </div>
          <TabsList className="mt-2 w-full">
            <TabsTrigger value="react" className="w-full">
              React
            </TabsTrigger>
            <TabsTrigger value="web" className="w-full">
              Web Component
            </TabsTrigger>
            <TabsTrigger value="api" className="w-full">
              API
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value="react"
            className="border-0 focus:ring-0 focus:ring-offset-0"
          >
            <p className="my-2 font-bold">Installation</p>
            <CodeDisplaySimple code="npm i @mendable/search" small={true} />
            <p className="mt-4 mb-2 text font-bold">Usage</p>
            <p className="mb-2 text-sm">Search Bar</p>
            <CodeDisplaySimple
              code={`import { MendableSearchBar } from '@mendable/search'

<MendableSearchBar anon_key={MENDABLE_ANON_KEY} />`}
              small={true}
            />
            <p className="my-2 text-sm">Floating Button</p>
            <CodeDisplaySimple
              code={`import { MendableFloatingButton } from '@mendable/search'

<MendableFloatingButton anon_key={MENDABLE_ANON_KEY} />`}
              small={true}
            />
            <DocumentationCTA />
          </TabsContent>
          <TabsContent value="web">
            <p className="mt-2 mb-4 font-bold">Usage</p>
            <p className="mb-2 text-sm">Search Bar</p>
            <CodeDisplaySimple
              code={`<div id="mendable-component"></div>

<script src="https://unpkg.com/@mendable/search@0.0.205/dist/umd/mendable-bundle.min.js"></script>
<script>
Mendable.initialize({
    anon_key: 'YOUR_ANON_KEY',
    type:"searchBar",
    elementId: "mendable-component" // required
    // all the other props for the component type
});
</script>`}
              small={true}
            />
            <p className="my-2 text-sm">Floating Button</p>
            <CodeDisplaySimple
              code={`<script src="https://unpkg.com/@mendable/search@0.0.205/dist/umd/mendable-bundle.min.js"></script>
<script>
Mendable.initialize({
    anon_key: 'YOUR_ANON_KEY',
    type:"floatingButton",
    // all the other props for the component type
});
</script>`}
              small={true}
            />
            <DocumentationCTA /></TabsContent>
            <TabsContent value="api">
            <DocumentationCTA title='API Documentantion' description='Learn how to use the Mendable API to build your own integrations.' link="https://docs.mendable.ai/mendable-api/overview" />
            </TabsContent>

        </Tabs>
        
      </PopoverContent>
    </Popover>
  );
}
